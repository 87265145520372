import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  formContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
  generalStatus: {
    padding: theme.spacing(0.5),
    borderRadius: '10px',
  },
  pending: {
    backgroundColor: 'rgb(96, 96, 96)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
  },
  approved: {
    backgroundColor: 'rgb(240, 173, 78)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
  },
  paid: {
    backgroundColor: 'rgb(30, 80, 190)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
  },
  registered: {
    backgroundColor: 'rgb(92, 184, 92)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
  },
  cancelled: {
    backgroundColor: 'red',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    height: '150px',
    borderRadius: '5px',
    border: '2px dashed rgb(128, 128, 128)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  uploadIcon: {
    color: 'rgb(128, 128, 128)',
    fontSize: '40px',
  },
  uploadButton: {
    background: 'red',
  },
  bankSlip: {
    width: '100%',
    maxHeight: '500px',
    objectFit: 'contain',
  },
}));
