import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Container, Grid, Typography } from '@material-ui/core';
import CustomTable from '../../../shared/Table';
import { useStyles } from './styles';
import EditIcon from '@material-ui/icons/Edit';

import { getAllPersonInCharge } from '../../../../api/personInCharge';

const PersonInChargeLibrary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [PICs, setPICs] = useState([]);

  useEffect(() => {
    dispatch(getAllPersonInCharge()).then((allPersonInCharge) => {
      if (allPersonInCharge) {
        setPICs(allPersonInCharge);
      }
    });
  }, [dispatch]);

  const tableHeaders = ['姓名', '电子邮件', ''];
  const tableData = PICs?.map((pic) => [
    pic.name,
    pic.email,
    <Button
      variant="contained"
      key={`${pic._id}-button`}
      className={classes.editButton}
      onClick={() => {
        history.push(`/admin/person-in-charge/edit/${pic._id}`);
      }}
    >
      <EditIcon fontSize="small" />
    </Button>,
  ]);
  const columnAlign = ['', '', 'center'];
  const columnWidth = ['37.5%', '37.5%', '25%'];

  return (
    <Container className={classes.mainContainer} maxWidth="md">
      <Typography variant="h4" className={classes.pageTitle}>
        负责人
      </Typography>
      <Grid container className={classes.tableContainer}>
        <CustomTable
          tableHeaders={tableHeaders}
          tableData={tableData}
          columnWidth={columnWidth}
          columnAlign={columnAlign}
        />
      </Grid>
    </Container>
  );
};

export default PersonInChargeLibrary;
