import Eelin from '../../images/profile-1.png';
import MarkChen from '../../images/profile-2.png';
import BaoYi from '../../images/profile-3.png';
import WenLin from '../../images/profile-4.png';
import HongWeng from '../../images/profile-5.png';
import ManHong from '../../images/profile-6.png';
import EelinM from '../../images/profile-mobile-1.png';
import MarkChenM from '../../images/profile-mobile-2.png';
import BaoYiM from '../../images/profile-mobile-3.png';
import WenLinM from '../../images/profile-mobile-4.png';
import HongWengM from '../../images/profile-mobile-5.png';
import ManHongM from '../../images/profile-mobile-6.png';

export const profileList = [
  {
    image: Eelin,
    mobileImage: EelinM,
    alt: 'Goh Ee Lin',
    name: '吴伊琳',
    info: '毕业于理科大学管理系（Universiti Sains Malaysia, USM)。她是创立金融咨询公司 SMARTg 的行销总监(Managing Director)。她已经从事这个行业超过10年，实现了多种收入来源。她也是大东方马来西亚最年轻的集团销售经理。Goh Ee Lin 女士在这个职业生涯中也获得了多个奖项，其中包括“百万圆桌2020”，“第18届 Advisers 金冠奖等。',
    infoEng:
      'Graduated from the Management Department of the University of Science (Universiti Sains Malaysia, USM). She is the Managing Director who founded SMARTg, a financial consulting company. She has been in this industry for more than 10 years and has realized multiple sources of income. She is also the youngest group sales manager of Great Eastern Malaysia. Ms. Goh Ee Lin has also won many awards in this career, including "Million Round Table 2020", "18th Advisers Golden Crown Award, etc.".',
    absoluteURI: 'https://wa.link/pxnu96',
  },
  {
    image: MarkChen,
    mobileImage: MarkChenM,
    alt: 'Mark Chen',
    name: '马克',
    info: '我是马克 Mark Chen, 拥有10年的理财规划经验。目前已提供超过500人专业理财方案，为客户评估风险、转移风险、做风险规划、减少客户的担忧，为客户寻找适合的理财管道，并且协助客户累积财富。拥有工程系背景的我，善于分析和运用图标，让客户一目了然自己的财务目标以及财务缺口。我的使命就是持续地为客户创造最大的价值。',
    infoEng: '',
    absoluteURI: 'http://markchen.watsap.me/',
  },
  {
    image: BaoYi,
    mobileImage: BaoYiM,
    alt: 'Goh Bao Yi',
    name: '吴宝仪',
    info: '我是吴宝仪，是一名超过 5 年经验的精明理财规划师兼注册会计师。我加入人寿保险行业的使命是能为大众无论个人或公司，我可以针对客户的财务健康状况建议提出对策，帮助客户学会如何设定自己投资理财的实际执行步骤；帮助客户学会自己编制个人资产负债表的损益表，登记收入与支出日记账，科学地分析自己的财务状况。同时，也要让更多像我这样的年轻人知道理财的重要性和一起发展在这个理财事业。',
    infoEng:
      'I am Goh Poh Yee, a financial planner and certified public accountant with more than 5 years of experience. My mission to join the life insurance industry is to be able to provide countermeasures for the public, regardless of individual or company, for the financial health of customers, to help customers learn how to set the actual implementation steps of their own investment and financial management; to help customers learn to prepare their own personal assets and liabilities. The profit and loss statement of the table, the registration of income and expenditure journals, and the scientific analysis of their financial status. At the same time, let more young people like me know the importance of financial management and develop together in this financial management career.',
    absoluteURI: 'http://gohpohyee.watsap.me/',
  },
  {
    image: WenLin,
    mobileImage: WenLinM,
    alt: 'Wu Wen Lin',
    name: '巫文琳',
    info: '你好，我是文琳！有我在，团队气氛嗨！理财规划师不但可以帮助顾客资产分配，搭配适当的理财商品，更是让我对未来充满能量。为了能更好地帮助客户，自我提升也是我一直在进行的事，而我也相信努力终会有回报。我在 SMARTg 负责售前售后的相关资讯，资料提供，疑难解析，售后服务报表提供，资产组合调整和提醒和协助进行，减轻大家的烦恼和问题，不止团队，我的人生价值也是希望带幸福快乐给身边遇到的人。',
    infoEng:
      "Hello，I'm Wen Lin！I'm good at making the atmosphere harmorious.As a financial planner not only help customers allocate their assets. With appropriate financial products, it makes me full of energy for the future.In order to better help customers, self-improvement is also something I have been doing.And I also believe that hard work will pay off in the end.I am responsible for pre-sales and after-sales related information at SMARTg,Providing data, analyzing difficult problems, providing after-sales service reports,asset portfolio adjustments and reminders and assistance to alleviate everyone’s worries and problems,Not only the team, but the value of my life is also to bring happiness to the people I meet around me.",
    absoluteURI: 'http://vernicewu.watsap.me/',
  },
  {
    image: HongWeng,
    mobileImage: HongWengM,
    alt: 'Loh Hong Wen',
    name: '卢鸿龙',
    info: '凭此着想带给身边朋友更好的计划和专业知识，同时也能提高社会历练和提升自身价值的想法，因此，我在2018年正式加入全职寿险代理员这个大家庭，运用理财规划程序，帮助别人安排财务事务，设计实现生活目标的专业人员。帮助客户制定理财规划策略，并通过出售相关的金融产品来实施该策略，以实现客户的理财目标。',
    infoEng:
      'I want to bring better planning and professional knowledge to my friends, and at the same time improve social experience and enhance my own value. Therefore, I officially joined the big family of SMARTg of full-time life insurance agents in 2018 and used financial planning procedures to help others arrange financial affairs and design professionals to achieve life goals. I hope I can help clients formulate financial planning strategies and implement this strategy by selling related financial products to achieve their financial goals.',
    absoluteURI: 'http://lohhongweng.watsap.me/',
  },
  {
    image: ManHong,
    mobileImage: ManHongM,
    alt: 'Ang Man Hong',
    name: '洪欣蔓',
    info: '我有两个样。第一，老老实实对待每个人的样。第二，当然是无法抵挡的笑容 。你好，照片里是我，洪欣蔓；我在SMARTg里，是位一直给予团队最大的支持和配合的人，也会针对客户的财务状况及需求进行完整的理财分析，帮助客户学会如何设定人生与理财的目标，了解自身或家庭的实际财务状况，定期为客户提供个人理财的最新资料，尽责的把每个客户服务好就是我人生最大的宗旨。',
    infoEng:
      'I have two roles. First, treat everyone honestly. Second, of course, an irresistible smile. Hello, the photo one is me, Ang Shin Man; In SMARTg, I am a person who has always provided the greatest support and cooperation to the team. I will also conduct a complete financial analysis based on the financial situation and needs of customers to help customers learn how to set up Setting the goals of life and financial management, understanding the actual financial situation of oneself or family, regularly providing customers with the latest information on personal financial management, and serving each customer conscientiously is the greatest purpose of my life.',
    absoluteURI: 'http://angshiman.watsap.me/',
  },
];
