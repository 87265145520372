export const INITIAL_STATE = {
  name: '',
  email: '',
};

export const validateData = (personInCharge) => {
  const errorState = { ...INITIAL_STATE };

  if (personInCharge.name.length === 0) {
    errorState.name = '请把姓名填上';
  }
  if (personInCharge.email.length === 0) {
    errorState.email = '请把电子邮件填上';
  }

  const hasError = errorState.name !== '' || errorState.email !== '';

  return { errorState, hasError };
};
