import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import styles from '../About/styles.module.css';

const Profile = ({ alt, image, name, info, infoEng, absoluteURI }) => {
  return (
    <>
      <div className={styles['profile-component']}>
        <img src={image} alt={alt} />
        <div className={styles['profile-text-container']}>
          <p className={styles['profile-name']}>
            {name}
            {/* <a href="https://www.youtube.com"> */}
            <a href={absoluteURI}>
              <i className="float-right fab fa-whatsapp" data-tip data-for="whatsapp"></i>
            </a>
          </p>
          <span className={styles['profile-info']}>
            {info}
            <br />
            <hr />
            {infoEng}
          </span>
        </div>
      </div>
      <ReactTooltip id="whatsapp" place="top" effect="solid">
        点击链接至Whatsapp
      </ReactTooltip>
    </>
  );
};

Profile.propTypes = {
  alt: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  infoEng: PropTypes.string.isRequired,
  absoluteURI: PropTypes.string.isRequired,
};

export default Profile;
