import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import { useStyles } from './styles';
import TextField from '../../shared/TextField';
import Button from '../../shared/Button';
import { Alert } from '@material-ui/lab';
import { INITIAL_STATE, validateData } from './utils';
import { registerAccount } from '../../../api/user';
import moment from 'moment';

const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector((state) => state.user.loggedIn);

  const [registerInfo, setRegisterInfo] = useState(INITIAL_STATE);
  const [validationState, setValidationState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (loggedIn) {
      history.replace('/');
    }
  }, [history, loggedIn]);

  const handleRegister = (e) => {
    e.preventDefault();
    const { errorState, hasError } = validateData(registerInfo);

    setValidationState(errorState);
    if (!hasError) {
      dispatch(
        registerAccount(registerInfo, () => {
          history.push('/auth');
        }),
      ).then((response) => {
        if (response === 'conflict') {
          setValidationState({ ...errorState, email: '该邮件已注册，请使用其他邮件' });
        }
      });
    }
  };

  const handleInputChange = (e) => {
    setRegisterInfo((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  return (
    <Container className={classes.mainContainer} maxWidth={false}>
      <Container maxWidth="sm" className={classes.formContainer}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <form onSubmit={handleRegister} autoComplete="off">
              <Grid item container justifyContent="center" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">
                    注册
                  </Typography>
                </Grid>
                {validationState.password && (
                  <Grid item xs={12}>
                    <Alert severity="error" style={{ fontSize: '0.8rem' }}>
                      密码必须符合以下规格：
                      <ul>
                        <li>至少8个字符</li>
                        <li>至少1个大写字母</li>
                        <li>至少1个小写字母</li>
                        <li>至少1个数字</li>
                        <li>至少1个符号</li>
                      </ul>
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="电子邮件"
                    type="email"
                    name="email"
                    value={registerInfo.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={!!validationState.email}
                    helperText={validationState.email}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    label="密码"
                    type="password"
                    name="password"
                    value={registerInfo.password}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={!!validationState.password}
                    helperText={validationState.password}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="确认密码"
                    type="password"
                    name="confirmPassword"
                    value={registerInfo.confirmPassword}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={!!validationState.confirmPassword}
                    helperText={validationState.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="姓名"
                    type="text"
                    name="name"
                    value={registerInfo.name}
                    onChange={handleInputChange}
                    pattern="^([^0-9]*)$"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="生日"
                    type="date"
                    name="birthDate"
                    value={registerInfo.birthDate}
                    onChange={handleInputChange}
                    max={moment().format('YYYY-MM-DD')}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="联络号码"
                    type="text"
                    name="phoneNum"
                    value={registerInfo.phoneNum}
                    onChange={handleInputChange}
                    pattern="^(\+?6?01)[0-46-9]-*[0-9]{7,8}$"
                    placeholder="012-1234567"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-gender-label">性别</InputLabel>
                    <Select
                      labelId="select-gender-label"
                      value={registerInfo.gender}
                      onChange={handleInputChange}
                      label="性别"
                      name="gender"
                      required
                    >
                      <MenuItem value="" disabled>
                        请选择
                      </MenuItem>
                      <MenuItem value="male">男</MenuItem>
                      <MenuItem value="female">女</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="职业"
                    type="text"
                    name="job"
                    value={registerInfo.job}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-educationLevel-label">学历</InputLabel>
                    <Select
                      labelId="select-educationLevel-label"
                      value={registerInfo.educationLevel}
                      onChange={handleInputChange}
                      label="学历"
                      name="educationLevel"
                      required
                    >
                      <MenuItem value="" disabled>
                        请选择
                      </MenuItem>
                      <MenuItem value="无学历">无学历</MenuItem>
                      <MenuItem value="SPM">SPM</MenuItem>
                      <MenuItem value="STPM">STPM</MenuItem>
                      <MenuItem value="Diploma">Diploma</MenuItem>
                      <MenuItem value="Degree">Degree</MenuItem>
                      <MenuItem value="Master">Master</MenuItem>
                      <MenuItem value="PhD">PhD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container xs={12} justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Button label="提交" variant="primary" fullWidth={true} />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item className={classes.divider}>
            <Divider />
          </Grid>
          <Grid item>
            <span style={{ marginRight: '8px' }}>已拥有账号？</span>
            <Link to="/auth" className={classes.hyperlink}>
              返回登入
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Register;
