import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { useStyles } from './styles';
import CustomTable from '../../../shared/Table';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getAllCareers } from '../../../../api/career';

const CareerTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [careers, setCareers] = useState([]);

  useEffect(() => {
    dispatch(getAllCareers()).then((allCareers) => {
      if (allCareers) {
        setCareers(allCareers);
      }
    });
  }, [dispatch]);

  const tableHeaders = ['应聘职位', ''];
  const tableData = careers?.map((career) => [
    career.title,
    <Button
      variant="contained"
      key={`${career._id}-button`}
      className={classes.editButton}
      onClick={() => {
        history.push(`/admin/careers/edit/${career._id}`);
      }}
    >
      <EditIcon fontSize="small" />
    </Button>,
  ]);
  const columnAlign = ['', 'center'];
  const columnWidth = ['75%', '25%'];

  return (
    <Container className={classes.mainContainer} maxWidth="sm">
      <Typography variant="h4" className={classes.pageTitle}>
        应聘
      </Typography>
      <Grid container className={classes.tableContainer}>
        <CustomTable
          tableHeaders={tableHeaders}
          tableData={tableData}
          columnWidth={columnWidth}
          columnAlign={columnAlign}
        />
      </Grid>
    </Container>
  );
};

export default CareerTable;
