import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(2),
  },
  errorHeading: {
    fontSize: '8rem',
  },
  errorDesc: {
    fontSize: '4rem',
  },
}));
