import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useStyles } from './styles';
import { Container, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getAllCareers } from '../../api/career';
import career1 from '../../images/career-1.png';
import career2 from '../../images/career-2.png';
import career3 from '../../images/career-3.png';
import career4 from '../../images/career-4.png';
import career5 from '../../images/career-5.png';
import career6 from '../../images/career-6.png';
import career7 from '../../images/career-7.png';
import career8 from '../../images/career-8.png';
import career9 from '../../images/career-9.png';
import career10 from '../../images/career-10.png';
import career11 from '../../images/career-11.png';
import career12 from '../../images/career-12.png';
import career13 from '../../images/career-13.png';
import career14 from '../../images/career-14.png';
import careerm1 from '../../images/career-mobile-1.png';
import careerm2 from '../../images/career-mobile-2.png';
import careerm3 from '../../images/career-mobile-3.png';
import careerm4 from '../../images/career-mobile-4.png';
import careerm5 from '../../images/career-mobile-5.png';
import careerm6 from '../../images/career-mobile-6.png';
import careerm7 from '../../images/career-mobile-7.png';
import careerm8 from '../../images/career-mobile-8.png';
import careerm9 from '../../images/career-mobile-9.png';
import careerm10 from '../../images/career-mobile-10.png';
import careerm11 from '../../images/career-mobile-11.png';
import careerm12 from '../../images/career-mobile-12.png';
import careerm13 from '../../images/career-mobile-13.png';
import careerm14 from '../../images/career-mobile-14.png';

const Career = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [careers, setCareers] = useState([]);
  const [selectedJob, setSelectedJob] = useState(0);
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  useEffect(() => {
    dispatch(getAllCareers()).then((careers) => {
      if (careers) {
        setCareers(careers);
      }
    });
  }, [dispatch]);

  return (
    <>
      {/* <div className={classes.coverImage}>
        <Typography variant="h2" className={classes.joinUs}>
          Join Us
        </Typography>
      </div>
      <Container maxWidth="xl">
        <Typography variant="h3" className={classes.smartg} align="center">
          SMARTg
        </Typography>
        <Container maxWidth="md">
          <Typography variant="body2" align="center" className={classes.introduction}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sem diam, auctor sit
            amet quam quis, aliquet blandit velit. Donec gravida sodales orci, maximus condimentum
            ante malesuada vel. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce
            efficitur turpis neque, sed molestie dolor efficitur a. Mauris vel accumsan sapien, non
            euismod metus. Duis id ipsum et nisl interdum molestie eget ut sem. Ut ullamcorper,
            nulla et blandit ornare, erat sapien ultrices metus, nec consequat massa purus ut arcu.
            Duis semper accumsan odio, sed ultricies massa sagittis at. Maecenas luctus tincidunt
            interdum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
            turpis egestas. Nunc commodo justo iaculis mi finibus hendrerit. Ut non nulla mollis ex
            porta fringilla eu quis ex. Donec egestas eget sem in tempor.
          </Typography>
        </Container>

        <Container maxWidth="md" className={classes.jobNameContainer}>
          <Grid container justifyContent="center" alignItems="center">
            {careers.map((career, index) => (
              <Grid
                item
                xs={12}
                sm={3}
                md={2}
                className={`${classes.jobNameFrame} ${
                  index === selectedJob ? classes.selectedJob : ''
                }`}
                key={index}
                onClick={() => setSelectedJob(index)}
              >
                <Typography variant="body1" align="center">
                  {career.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container maxWidth="lg" className={classes.jobDescriptionContainer}>
          <Typography variant="h6" gutterBottom>
            工作内容
          </Typography>
          <Typography variant="body1">{careers[selectedJob]?.description}</Typography>
        </Container>

        <Container maxWidth="lg" className={classes.qualificationContainer}>
          <Typography variant="h6">
            <ul>
              {careers[selectedJob]?.qualification?.map((qualification) => (
                <li key={qualification} className={classes.qualification}>
                  {qualification}
                </li>
              ))}
            </ul>
          </Typography>
          <Typography variant="body1"></Typography>
        </Container>
      </Container> */}
      {isPortrait ? (
        <>
          <img src={careerm1} className={classes.image} />
          <img src={careerm2} className={classes.image} />
          <img src={careerm3} className={classes.image} />
          <img src={careerm4} className={classes.image} />
          <img src={careerm5} className={classes.image} />
          <img src={careerm6} className={classes.image} />
          <img src={careerm7} className={classes.image} />
          <img src={careerm8} className={classes.image} />
          <img src={careerm9} className={classes.image} />
          <img src={careerm10} className={classes.image} />
          <img src={careerm11} className={classes.image} />
          <img src={careerm12} className={classes.image} />
          <img src={careerm13} className={classes.image} />
          <img src={careerm14} className={classes.image} />
        </>
      ) : (
        <>
          <img src={career1} className={classes.image} />
          <img src={career2} className={classes.image} />
          <img src={career3} className={classes.image} />
          <img src={career4} className={classes.image} />
          <img src={career5} className={classes.image} />
          <img src={career6} className={classes.image} />
          <img src={career7} className={classes.image} />
          <img src={career8} className={classes.image} />
          <img src={career9} className={classes.image} />
          <img src={career10} className={classes.image} />
          <img src={career11} className={classes.image} />
          <img src={career12} className={classes.image} />
          <img src={career13} className={classes.image} />
          <img src={career14} className={classes.image} />
        </>
      )}
    </>
  );
};

export default Career;
