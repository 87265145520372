import { API_URL } from './env';

const API_ROUTES = {
  auth: {
    login: { url: `${API_URL}/auth/login`, method: 'POST' },
    logout: { url: `${API_URL}/auth/logout`, method: 'POST' },
    refreshToken: { url: `${API_URL}/auth/refresh-token`, method: 'POST' },
  },
  users: {
    register: { url: `${API_URL}/users`, method: 'POST' },
    forgotPassword: { url: `${API_URL}/users/forgot-password`, method: 'POST' },
    resetPassword: { url: `${API_URL}/users/reset-password`, method: 'POST' },
    changePassword: { url: (id) => `${API_URL}/users/${id}/change-password`, method: 'PUT' },
    getLoggedIn: { url: `${API_URL}/users/logged-in`, method: 'GET' },
    getUser: { url: `${API_URL}/users`, method: 'GET' },
    updateUser: { url: `${API_URL}/users`, method: 'PUT' },
  },
  disc: {
    create: { url: `${API_URL}/disc`, method: 'POST' },
    getResult: { url: `${API_URL}/disc`, method: 'GET' },
  },
  contactUs: {
    sendMessage: { url: `${API_URL}/contact-us`, method: 'POST' },
  },
  activities: {
    getActivity: { url: `${API_URL}/activities`, method: 'GET' },
    createActivity: { url: `${API_URL}/activities`, method: 'POST' },
    updateActivity: { url: `${API_URL}/activities`, method: 'PUT' },
    deleteActivity: { url: `${API_URL}/activities`, method: 'DELETE' },
  },
  careers: {
    getCareer: { url: `${API_URL}/careers`, method: 'GET' },
    createCareer: { url: `${API_URL}/careers`, method: 'POST' },
    updateCareer: { url: `${API_URL}/careers`, method: 'PUT' },
    deleteCareer: { url: `${API_URL}/careers`, method: 'DELETE' },
  },
  registrations: {
    getAllRegistrations: { url: `${API_URL}/registrations/all-registrations`, method: 'GET' },
    bulkUpdateRegistrations: { url: `${API_URL}/registrations/bulk-update-status`, method: 'PUT' },
    getUserRegistrations: { url: `${API_URL}/registrations/user-registrations`, method: 'GET' },
    getRegistration: { url: `${API_URL}/registrations/`, method: 'GET' },
    updateRegistration: { url: `${API_URL}/registrations/`, method: 'PUT' },
    createRegistration: { url: `${API_URL}/registrations/`, method: 'POST' },
  },
  personInCharge: {
    getPersonInCharge: { url: `${API_URL}/person-in-charge`, method: 'GET' },
    createPersonInCharge: { url: `${API_URL}/person-in-charge`, method: 'POST' },
    updatePersonInCharge: { url: `${API_URL}/person-in-charge`, method: 'PUT' },
    deletePersonInCharge: { url: `${API_URL}/person-in-charge`, method: 'DELETE' },
  },
};

export default API_ROUTES;
