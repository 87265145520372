import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { initiateAuthInterceptor } from './utils/axios';

initiateAuthInterceptor();

// Disable console.log and console.error in non development environment
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.error = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
