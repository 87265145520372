import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    minWidth: '300px',
    '@media(min-width: 780px)': {
      minWidth: '450px',
    },
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: '8rem',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: '8rem',
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: '8rem',
  },
  dangerButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
}));
