import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useStyles } from './styles';
import Button from '../../shared/Button';
import { openModal } from '../../../redux/modal/actions';
import { getRegistration, updateBankslip } from '../../../api/registration';
import { getLoggedInUser } from '../../../redux/user/actions';
import { getBase64 } from '../../../utils/convertBase64';

const MasterclassRegistrationDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const user = useSelector((state) => state.user);

  const [registration, setRegistration] = useState();
  const [bankSlip, setBankSlip] = useState('');
  const canUpload = registration?.status === 'approved' || registration?.status === 'paid';
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    noClick: true,
    maxFiles: 1,
    maxSize: 10000000, // 10MB max
    multiple: false,
    onDrop: async (image) => {
      if (image[0]) {
        const base64img = await getBase64(image[0]);
        setBankSlip(base64img);
      }
    },
  });

  useEffect(() => {
    if (!user.loggedIn) {
      dispatch(getLoggedInUser()).then((user) => {
        if (!user) {
          history.replace('/auth');
        }
      });
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    dispatch(getRegistration(id)).then((response) => {
      if (!response) {
        dispatch(
          openModal({
            type: 'error',
            title: '错误',
            message: '找不到该课程注册资料。',
            primaryButtonCallback: () => history.replace(`/user/registrations`),
          }),
        );
      } else {
        setRegistration(response);
        setBankSlip(response.bankSlip || '');
      }
    });
  }, [dispatch, history, id]);

  const handleUploadClick = () => {
    const { ref } = getInputProps();
    ref.current?.click();
  };

  const convertStatusToChinese = (status) => {
    switch (status) {
      case 'pending':
        return '审核中';
      case 'approved':
        return '待付款';
      case 'paid':
        return '已付款';
      case 'registered':
        return '已报名';
      case 'cancelled':
        return '已取消';
      default:
        return '?';
    }
  };

  const handleSubmit = () => {
    dispatch(
      updateBankslip(bankSlip, id, bankSlip === registration.bankSlip, () => {
        history.push(`/user/registrations`);
      }),
    );
  };

  return (
    <Container className={classes.mainContainer} maxWidth="sm">
      <Typography variant="h4" className={classes.pageTitle}>
        课程：{registration?.activity.name}
      </Typography>
      <Grid
        container
        direction="column"
        className={classes.formContainer}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              课程名称：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            {registration?.activity.name}
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              课程日期：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            {moment(registration?.activity.activityDate).format('DD-MM-YYYY') || '-'}
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              报名状态：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <span className={`${classes[registration?.status]} ${classes.generalStatus}`}>
              {convertStatusToChinese(registration?.status)}
            </span>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              报名日期：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            {moment(registration?.createdAt).format('DD-MM-YYYY') || '-'}
          </Grid>
        </Grid>
        {registration?.status !== 'pending' && registration?.status !== 'cancelled' && (
          <Grid item container alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                付款收据：
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              {bankSlip === '' ? (
                <>
                  {canUpload ? (
                    <div {...getRootProps()} className={classes.imageContainer}>
                      <input {...getInputProps()} />
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CloudUploadIcon className={classes.uploadIcon} />
                        <Typography variant="body1" component="span" gutterBottom>
                          把图片拖放到此处
                        </Typography>
                        <button onClick={handleUploadClick}>上传</button>
                      </Grid>
                    </div>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <img src={bankSlip} className={classes.bankSlip} />
                  {canUpload && (
                    <button onClick={() => setBankSlip('')} style={{ marginTop: '8px' }}>
                      重新上传
                    </button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {canUpload && (
        <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '8px' }}>
          <Grid item>
            <Button
              variant="primary"
              sharedClasses="normal-text"
              label="保存"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default MasterclassRegistrationDetails;
