import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.css';
import logoWhite from '../../../images/logo-white.png';
import logoBlack from '../../../images/logo-black.png';
import { navigationConfig } from './navigationConfig';
import { logout } from '../../../redux/user/actions';

const Navbar = ({ isSolid, isWhite }) => {
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1180px)' });
  const isHeight500 = useMediaQuery({ query: '(max-height: 500px)' });
  const isWidth500 = useMediaQuery({ query: '(max-width: 500px)' });
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const user = useSelector((state) => state.user);
  const isAdmin = user?.loggedIn && user?.details?.role === 'admin';
  const [menuOpen, setMenuOpen] = useState(false);
  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div
      className={`${styles['navbar-container']} ${
        isSolid ? styles['solid'] : styles['transparent']
      }`}
    >
      <Link to="/">
        <img
          className={`${styles['logo']} float-left ${
            isWidth500 || isHeight500 ? styles['short'] : styles['biggerLogo']
          }`}
          src={isWhite ? logoWhite : logoBlack}
        />
      </Link>
      <input
        type="checkbox"
        checked={menuOpen}
        id={styles['chk']}
        style={{ display: 'none' }}
        onChange={() => {}}
      />
      <div
        className={`${styles['navigation-container']} float-right ${
          isMobileScreen ? styles['mobile-navigation-container'] : ''
        } ${!isWhite && isMobileScreen ? styles['whiteBackground'] : ''}`}
      >
        {navigationConfig.map((navigation) => (
          <Link
            to={navigation.to}
            className={`${isMobileScreen ? styles['hide'] : ''} ${
              !isWhite ? styles['navlink-black'] : styles['navlink']
            }`}
            key={navigation.label}
            onClick={() => setMenuOpen(false)}
          >
            {navigation.label}
          </Link>
        ))}

        {loggedIn ? (
          isAdmin ? (
            <>
              <Link
                to="/admin/masterclass/edit"
                className={` ${!isWhite ? styles['navlink-black'] : styles['navlink']} ${
                  isMobileScreen ? styles['hide'] : ''
                }`}
                onClick={() => setMenuOpen(false)}
              >
                管理员专区
              </Link>
              <a
                className={` ${!isWhite ? styles['navlink-black'] : styles['navlink']} ${
                  isMobileScreen ? styles['hide'] : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={logoutHandler}
              >
                登出
              </a>
            </>
          ) : (
            <>
              <Link
                to="/user/profile"
                className={` ${!isWhite ? styles['navlink-black'] : styles['navlink']} ${
                  isMobileScreen ? styles['hide'] : ''
                }`}
                onClick={() => setMenuOpen(false)}
              >
                会员专区
              </Link>
              <a
                className={` ${!isWhite ? styles['navlink-black'] : styles['navlink']} ${
                  isMobileScreen ? styles['hide'] : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={logoutHandler}
              >
                登出
              </a>
            </>
          )
        ) : (
          <>
            <Link
              to="/auth"
              className={` ${!isWhite ? styles['navlink-black'] : styles['navlink']} ${
                isMobileScreen ? styles['hide'] : ''
              }`}
              onClick={() => setMenuOpen(false)}
            >
              登入
            </Link>
            <Link
              to="/register"
              className={` ${!isWhite ? styles['navlink-black'] : styles['navlink']} ${
                isMobileScreen ? styles['hide'] : ''
              }`}
              onClick={() => setMenuOpen(false)}
            >
              注册
            </Link>
          </>
        )}
      </div>
      <label
        htmlFor={styles['chk']}
        className={`${styles['show-menu-btn']} ${
          isMobileScreen ? styles['show'] : styles['hide']
        } ${isWhite ? styles['white'] : ''}`}
        onClick={() => setMenuOpen(true)}
      >
        <i className="fas fa-bars"></i>
      </label>
      <label
        htmlFor={styles['chk']}
        className={`${styles['hide-menu-btn']} ${
          isMobileScreen ? styles['show'] : styles['hide']
        } ${isWhite ? styles['white'] : ''}`}
        onClick={() => setMenuOpen(false)}
      >
        <i className="fas fa-times"></i>
      </label>
    </div>
  );
};

Navbar.propTypes = {
  isWhite: PropTypes.bool,
  isSolid: PropTypes.bool,
  // onClick: PropTypes.func.isRequired,
  // variant: PropTypes.string,
  // containerWidth: PropTypes.number,
  // containerHeight: PropTypes.number,
  // containerMargin: PropTypes.string,
};

export default Navbar;
