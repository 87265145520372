import { PASSWORD_REGEX } from '../../../config/constants';

export const INITIAL_STATE = {
  email: '',
  password: '',
  confirmPassword: '',
  name: '',
  birthDate: '',
  phoneNum: '',
  gender: '',
  job: '',
  educationLevel: '',
};

export const validateData = (registerInfo) => {
  const errorState = { ...INITIAL_STATE };

  if (!PASSWORD_REGEX.test(registerInfo.password)) {
    errorState.password = '密码必须符合规格';
  } else if (registerInfo.password !== registerInfo.confirmPassword) {
    errorState.confirmPassword = '密码必须一致';
  }

  const hasError = Object.values(errorState).some((value) => value !== '');

  return { errorState, hasError };
};
