import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  table: {
    backgroundColor: 'white',
    width: '100%',
  },
  tableHeader: {
    backgroundColor: 'rgb(49, 108, 244)',
    color: 'white',
    minWidth: '100px',
  },
  tableData: {
    cursor: 'pointer',
  },
}));
