import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { useMediaQuery } from 'react-responsive';

import styles from './styles.module.css';
import { adminSidebarMenu, userSidebarMenu } from './sidebarConfig';
import { getInitialExpandedState } from './utils';

const AdminSidebar = ({ type }) => {
  const location = useLocation();
  const sideBarMenu = useMemo(() => {
    if (type === 'admin') {
      return adminSidebarMenu;
    } else {
      return userSidebarMenu;
    }
  }, [type]);

  const [itemExpanded, setItemExpanded] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  useEffect(() => {
    if (isMobileScreen) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isMobileScreen]);

  useEffect(() => {
    const initialExpandedState = getInitialExpandedState(sideBarMenu);
    Object.keys(sideBarMenu).forEach((itemKey) => {
      if (sideBarMenu[itemKey].to && location.pathname.includes(sideBarMenu[itemKey].to)) {
        initialExpandedState[itemKey] = true;
      } else if ('subMenu' in sideBarMenu[itemKey]) {
        Object.keys(sideBarMenu[itemKey].subMenu).forEach((subItemKey) => {
          if (
            sideBarMenu[itemKey].subMenu[subItemKey].to &&
            location.pathname.includes(sideBarMenu[itemKey].subMenu[subItemKey].to)
          ) {
            initialExpandedState[itemKey] = true;
          }
        });
      }
    });
    setItemExpanded(initialExpandedState);
    if (isMobileScreen) {
      setIsCollapsed(true);
    }
  }, [location.pathname, sideBarMenu, isMobileScreen]);

  const handleOpenItem = (key) => {
    const initialExpandedState = getInitialExpandedState(sideBarMenu);
    initialExpandedState[key] = true;
    setItemExpanded(initialExpandedState);
  };

  const triggerSidebar = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <>
      {isMobileScreen && (
        <div
          className={`${styles['overlay']} ${!isCollapsed && styles['overlay-active']}`}
          onClick={triggerSidebar}
        ></div>
      )}
      <div className={isCollapsed ? styles['sidebar-collapsed'] : styles['sidebar']}>
        <List>
          {Object.entries(sideBarMenu).map(([key, { label, to, subMenu = {} }]) => (
            <React.Fragment key={key}>
              <ListItem
                component={to ? Link : 'span'}
                to={to}
                className={styles['item']}
                onClick={() => {
                  if (!to) {
                    handleOpenItem(key);
                  }
                }}
              >
                <ListItemText>
                  <Typography
                    component="span"
                    className={
                      location.pathname.includes(key) || itemExpanded[key]
                        ? styles['item-text-active']
                        : styles['item-text']
                    }
                  >
                    {label}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Collapse in={itemExpanded[key]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {Object.entries(subMenu).map(([subMenuKey, { label, to }]) => (
                    <ListItem
                      key={subMenuKey}
                      component={Link}
                      to={to}
                      disableGutters
                      className={styles['nested-item']}
                    >
                      <Typography
                        className={
                          location.pathname.includes(to)
                            ? styles['nested-item-text-active']
                            : styles['nested-item-text']
                        }
                      >
                        {label}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </div>
      {isMobileScreen && (
        <div className={styles['trigger-sidebar']} onClick={triggerSidebar}>
          <MenuOpenIcon />
        </div>
      )}
    </>
  );
};

AdminSidebar.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AdminSidebar;
