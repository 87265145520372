import { GET_LOGGED_IN_USER, REMOVE_LOGGED_IN_USER } from './types';

const initialState = { loggedIn: false, details: {} };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER:
      return { loggedIn: true, details: action.payload };
    case REMOVE_LOGGED_IN_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
