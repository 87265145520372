let accessToken = '';
let isRefreshing = false;

export const setAccessToken = (token) => {
  accessToken = `Bearer ${token}`;
};

export const getAccessToken = () => {
  return accessToken;
};

export const getRefreshingFlag = () => {
  return isRefreshing;
};

export const setRefreshingFlag = (refreshing) => {
  isRefreshing = refreshing;
};
