export const navigationConfig = [
  {
    label: '首页',
    to: '/',
  },
  {
    label: '关于我们',
    to: '/about',
  },
  {
    label: '服务介绍',
    to: '/service',
  },
  // {
  //   label: '最新',
  //   to: '/event',
  // },
  {
    label: '最新活动/课程',
    to: '/masterclass',
  },
  {
    label: 'Join Us',
    to: '/career',
  },
  {
    label: '联络',
    to: '/contact-us',
  },
  {
    label: 'DISC测试',
    to: '/disc',
  },
];
