import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

export const mainTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.blue[100],
      // main: colors.lightBlue[200],
    },
    secondary: {
      main: colors.grey[300],
    },
    text: {
      primary: '#000000',
    },
    error: {
      main: colors.red[500],
    },
  },
  typography: {
    fontFamily: 'Noto Sans SC',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
  },
});
