import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Alert } from '@material-ui/lab';

import { useStyles } from './styles';
import TextField from '../../shared/TextField';
import Button from '../../shared/Button';
import { login } from '../../../api/auth';

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector((state) => state.user.loggedIn);

  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (loggedIn) {
      history.replace('/');
    }
  }, [history, loggedIn]);

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(
      login(credentials, () => {
        history.push('/');
      }),
    ).then((error) => {
      if (error) {
        setErrorMessage(error);
      }
    });
  };

  return (
    <Container className={classes.mainContainer} maxWidth={false}>
      <Container maxWidth="sm" className={classes.formContainer}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <form onSubmit={handleLogin}>
              <Grid item container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">
                    登入
                  </Typography>
                </Grid>
                {errorMessage.length > 0 && (
                  <Grid item xs={12}>
                    <Alert severity="error">{errorMessage}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="电子邮件"
                    fullWidth={true}
                    type="email"
                    value={credentials.email}
                    onChange={(e) =>
                      setCredentials((prevState) => ({ ...prevState, email: e.target.value }))
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="密码"
                    fullWidth={true}
                    type="password"
                    value={credentials.password}
                    onChange={(e) =>
                      setCredentials((prevState) => ({ ...prevState, password: e.target.value }))
                    }
                    required
                  />
                </Grid>
                <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Link to="/auth/forgot-password" className={classes.hyperlink}>
                      忘记密码?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Button label="登入" variant="primary" fullWidth={true} />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item className={classes.divider}>
            <Divider />
          </Grid>
          <Grid item>
            <span style={{ marginRight: '8px' }}>还未拥有账号？</span>
            <Link to="/register" className={classes.hyperlink}>
              注册账户
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Login;
