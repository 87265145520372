import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
  recordCount: {
    marginTop: theme.spacing(2),
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
  },
  picFilter: {
    width: '100%',
  },
}));
