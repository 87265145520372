import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { useStyles } from './styles';
import CustomTable from '../../../shared/Table';
import { useDispatch } from 'react-redux';
import { getAllDiscResults } from '../../../../api/disc';
import { getAllPersonInCharge } from '../../../../api/personInCharge';
import TextField from '../../../shared/TextField';

const DiscTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [discResults, setDiscResults] = useState([]);
  const [filteredDiscResults, setFilteredDiscResults] = useState([]);
  const [picFilter, setPicFilter] = useState('all');
  const [PICs, setPICs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const discResults = await dispatch(getAllDiscResults());
      if (discResults) {
        setDiscResults(discResults);
        setFilteredDiscResults(discResults);
      }

      const allPersonInCharge = await dispatch(getAllPersonInCharge());
      if (allPersonInCharge) {
        setPICs(allPersonInCharge);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setFilteredDiscResults(
      discResults.filter(
        (discResult) =>
          (!startDate ||
            (startDate && moment(discResult.createdAt).isSameOrAfter(startDate, 'day'))) &&
          (!endDate || (endDate && moment(discResult.createdAt).isSameOrBefore(endDate, 'day'))) &&
          (picFilter === 'all' || discResult.personInCharge?._id === picFilter),
      ),
    );
  }, [discResults, picFilter, startDate, endDate]);

  const tableHeaders = ['姓名', '联络号码', '邮件', '负责人', 'D', 'I', 'S', 'C'];
  const tableData = filteredDiscResults?.map((discResult) => [
    discResult.name,
    discResult.phoneNum,
    discResult.email,
    discResult.personInCharge?.name,
    discResult.result.D,
    discResult.result.I,
    discResult.result.S,
    discResult.result.C,
  ]);
  const columnWidth = ['15%', '15%', '25%', '15%', '7.5%', '7.5%', '7.5%', '7.5%'];

  return (
    <Container className={classes.mainContainer} maxWidth="lg">
      <Typography variant="h4" className={classes.pageTitle}>
        DISC测试记录
      </Typography>

      <Grid container className={classes.tableContainer}>
        <Grid item container spacing={2} className={classes.filterContainer}>
          <Grid item xs={12} md={4} lg={3}>
            <FormControl variant="outlined" className={classes.picFilter}>
              <InputLabel id="pic-filter-label">负责人</InputLabel>
              <Select
                labelId="pic-filter-label"
                id="pic-filter"
                value={picFilter}
                onChange={(e) => setPicFilter(e.target.value)}
                label="负责人"
              >
                <MenuItem value="all">全部</MenuItem>
                {PICs.map((pic) => (
                  <MenuItem value={pic._id} key={pic._id}>
                    {pic.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              label="开始日期"
              type="date"
              name="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={endDate}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              label="结束日期"
              type="date"
              name="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate}
              fullWidth
            />
          </Grid>
        </Grid>
        <CustomTable tableHeaders={tableHeaders} tableData={tableData} columnWidth={columnWidth} />
        <Typography variant="body1" className={classes.recordCount}>
          记录数: <b>{filteredDiscResults.length}</b>
        </Typography>
      </Grid>
    </Container>
  );
};

export default DiscTable;
