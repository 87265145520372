import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './styles';
import CustomTable from '../../shared/Table';
import { getUserRegistrations } from '../../../api/registration';
import { getLoggedInUser } from '../../../redux/user/actions';

const MasterclassRegistration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);

  const [registrations, setRegistrations] = useState([]);

  useEffect(() => {
    if (!user.loggedIn) {
      dispatch(getLoggedInUser()).then((user) => {
        if (!user) {
          history.replace('/auth');
        }
      });
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    dispatch(getUserRegistrations()).then((response) => {
      if (response) {
        setRegistrations(response);
      }
    });
  }, [dispatch]);

  const tableHeaders = ['课程名称', ''];
  const tableData = registrations?.map((registration) => [
    registration.activity.name,
    <Button
      variant="contained"
      key={`${registration._id}-button`}
      className={classes.viewButton}
      onClick={() => {
        history.push(`/user/registrations/${registration._id}`);
      }}
    >
      <SearchIcon fontSize="small" />
    </Button>,
  ]);

  const columnAlign = ['', 'center'];
  const columnWidth = ['75%', '25%'];
  return (
    <Container className={classes.mainContainer} maxWidth="sm">
      <Typography variant="h4" className={classes.pageTitle}>
        课程
      </Typography>
      <Grid container className={classes.tableContainer}>
        <CustomTable
          tableHeaders={tableHeaders}
          tableData={tableData}
          columnWidth={columnWidth}
          columnAlign={columnAlign}
        />
      </Grid>
    </Container>
  );
};

export default MasterclassRegistration;
