import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './styles.module.css';
import Button from '../shared/Button';
import RotateDevice from '../shared/RotateDevice';
import { getAllActivities } from '../../api/activity';
import { createRegistration } from '../../api/registration';
import { API_URL } from '../../config/env';
import ComingSoon from '../../images/coming-soon.webp';
// import ComingSoon from '../../images/coming-soon.jpeg';

const Masterclass = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const history = useHistory();

  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isShortDevice = useMediaQuery({ maxHeight: '500px' });

  const [masterclass, setMasterclass] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentActivity, setCurrentActivity] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const initialState = 0;
  // const hasNoMasterclass = masterclass.

  const handleActivityChange = (nextActivity) => {
    if (nextActivity === true) {
      setCurrentActivity(currentActivity + 1);
      currentActivity + 1 == masterclass.length ? setCurrentActivity(initialState) : '';
    } else {
      setCurrentActivity(currentActivity - 1);
      currentActivity - 1 < initialState ? setCurrentActivity(masterclass.length - 1) : '';
    }
    setCurrentSlide(initialState);
  };
  const handleSlideChange = (nextSlide) => {
    if (nextSlide === true) {
      setCurrentSlide(currentSlide + 1);
      currentSlide + 1 == masterclass[currentActivity].details.length
        ? setCurrentSlide(initialState)
        : '';
    } else {
      setCurrentSlide(currentSlide - 1);
      currentSlide - 1 < initialState
        ? setCurrentSlide(masterclass[currentActivity].details.length - 1)
        : '';
    }
  };
  const handleRegister = () => {
    isEmpty
      ? ''
      : loggedIn
      ? dispatch(createRegistration(masterclass[currentActivity]._id))
      : history.push('/auth');
  };

  useEffect(() => {
    dispatch(getAllActivities('masterclass')).then((allMasterclass) => {
      if (allMasterclass) {
        setMasterclass(allMasterclass);
      }
      if (allMasterclass == 0) {
        setIsEmpty(true);
      }
    });
  }, [dispatch]);

  return (
    <>
      {isPortrait ? <RotateDevice></RotateDevice> : ''}
      <div className={styles['masterclass-container']}>
        <div className={styles['slideshow-container']}>
          <div
            className={`${styles['overflow-image-container']} ${
              !isShortDevice ? 'vh-desktop' : ''
            }`}
          >
            {isEmpty ? (
              <img className={styles['activityImg']} src={ComingSoon} />
            ) : (
              <img
                className={styles['activityImg']}
                src={
                  masterclass[currentActivity]?.details[currentSlide]?.image
                    ? `${API_URL}/images/${masterclass[currentActivity]?.details[currentSlide]?.image}`
                    : ''
                }
              />
            )}
          </div>
          {masterclass[currentActivity]?.details[currentSlide]?.description.length < 1 ? (
            ''
          ) : (
            <div className={`${styles['overlay']} ${!isShortDevice ? 'vh-desktop' : ''}`}></div>
          )}
          <div className={`${styles['info-container']} ${!isShortDevice ? 'vh-desktop' : ''}`}>
            <div className={styles['text']}>
              {masterclass[currentActivity]?.details[currentSlide]?.description}
            </div>
          </div>
          {isEmpty ? (
            ''
          ) : (
            <div
              className={`${styles['navigator-container']} ${!isShortDevice ? 'vh-desktop' : ''}`}
            >
              <div className={styles['slideshow-navigator']}>
                <span
                  className={`float-left ${styles['arrow-left']}`}
                  onClick={() => {
                    handleSlideChange(false);
                  }}
                >
                  <span>上一页 </span>
                  <i className="fas fa-chevron-left normal-hover" style={{ padding: '0 10px' }}></i>
                </span>
                <span
                  className={`float-right ${styles['arrow-right']}`}
                  onClick={() => {
                    handleSlideChange(true);
                  }}
                >
                  <i
                    className="fas fa-chevron-right normal-hover"
                    style={{ padding: '0 10px' }}
                  ></i>
                  <span>下一页</span>
                </span>
              </div>
            </div>
          )}

          <div className={`${styles['bottom-container']} text-center`}>
            <div className={styles['activity-div']}>
              <div className="float-left">
                <i
                  className="fas fa-chevron-left normal-hover"
                  onClick={() => {
                    handleActivityChange(false);
                  }}
                ></i>
              </div>
              <span style={{ textTransform: 'uppercase' }}>
                {masterclass[currentActivity]?.name}
              </span>
              <div className="float-right">
                <i
                  className="fas fa-chevron-right normal-hover"
                  onClick={() => {
                    handleActivityChange(true);
                  }}
                ></i>
              </div>
            </div>
            <Button label="我要报名" variant="btn-activity" onClick={handleRegister} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Masterclass;
