import { Container, Divider, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import TextField from '../../shared/TextField';
import Button from '../../shared/Button';
import { useStyles } from './styles';
import { forgotPassword } from '../../../api/user';
import MailSent from '../../../images/MailSent.svg';

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector((state) => state.user.loggedIn);

  const [email, setEmail] = useState('');
  const [hasSent, setHasSent] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      history.replace('/');
    }
  }, [history, loggedIn]);

  const handleForgotPassword = (e) => {
    e.preventDefault();
    dispatch(forgotPassword(email)).then((response) => {
      if (response) {
        setHasSent(true);
      }
    });
  };

  return (
    <Container className={classes.mainContainer} maxWidth={false}>
      <Container maxWidth="sm" className={classes.formContainer}>
        {hasSent ? (
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item container xs={12} justifyContent="center" alignItems="center">
              <img src={MailSent} width="50%" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                成功发送
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                重置密码的邮件已发到您的邮件箱里。请根据邮件里的指示重置您的密码。
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center" alignItems="center">
              <Button label="返回" variant="primary" onClick={() => history.push('/auth')} />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <form onSubmit={handleForgotPassword}>
                <Grid item container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" align="center">
                      重置密码
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                      请输入您的电子邮件以发送重置密码的请求
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="电子邮件"
                      fullWidth={true}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item container xs={12} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Button label="提交" variant="primary" fullWidth={true} />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item>
              <Link to="/auth" className={classes.hyperlink}>
                返回登入
              </Link>
            </Grid>
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default ForgotPassword;
