import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
  pending: {
    backgroundColor: 'rgb(96, 96, 96)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgb(96, 96, 96)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgb(96, 96, 96)',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgb(96, 96, 96)',
    },
  },
  approved: {
    backgroundColor: 'rgb(240, 173, 78)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgb(240, 173, 78)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgb(240, 173, 78)',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgb(240, 173, 78)',
    },
  },
  paid: {
    backgroundColor: 'rgb(30, 80, 190)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgb(30, 80, 190)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgb(30, 80, 190)',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgb(30, 80, 190)',
    },
  },
  registered: {
    backgroundColor: 'rgb(92, 184, 92)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgb(92, 184, 92)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgb(92, 184, 92)',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'rgb(92, 184, 92)',
    },
  },
  cancelled: {
    backgroundColor: 'red',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'red',
    },
    '&.Mui-selected': {
      backgroundColor: 'red',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'red',
    },
  },
  previewText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    '&:visited': {
      color: 'blue',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  bankSlip: {
    maxHeight: '80vh',
    maxWidth: '80vw',
    objectFit: 'contain',
  },
}));
