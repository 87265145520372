import { Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import TextField from '../../shared/TextField';
import Button from '../../shared/Button';
import { useStyles } from './styles';
import { resetPassword } from '../../../api/user';
import { PASSWORD_REGEX } from '../../../config/constants';

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId, token } = useParams();

  const loggedIn = useSelector((state) => state.user.loggedIn);

  const [password, setPassword] = useState({ password: '', confirm: '' });
  const [passwordError, setPasswordError] = useState({ password: '', confirm: '' });

  useEffect(() => {
    if (loggedIn) {
      history.replace('/');
    }
  }, [history, loggedIn]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    const validationState = { password: '', confirm: '' };
    if (!PASSWORD_REGEX.test(password.password)) {
      validationState.password = '密码必须符合规格';
    } else if (password.password !== password.confirm) {
      validationState.confirm = '密码必须一致';
    }
    setPasswordError(validationState);
    if (!validationState.password && !validationState.confirm) {
      dispatch(
        resetPassword(userId, token, password.password, () => {
          history.replace('/auth');
        }),
      );
    }
  };

  return (
    <Container className={classes.mainContainer} maxWidth={false}>
      <Container maxWidth="sm" className={classes.formContainer}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <form onSubmit={handleResetPassword}>
              <Grid item container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">
                    重置密码
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    请在下方输入您账号的新密码
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="新密码"
                    fullWidth={true}
                    type="password"
                    value={password.password}
                    onChange={(e) =>
                      setPassword((prevState) => ({ ...prevState, password: e.target.value }))
                    }
                    required
                    error={!!passwordError.password}
                  />
                </Grid>
                {passwordError.password && (
                  <Grid item xs={11} style={{ color: 'red', fontSize: '0.8rem' }}>
                    密码必须符合以下规格：
                    <ul>
                      <li>至少8个字符</li>
                      <li>至少1个大写字母</li>
                      <li>至少1个小写字母</li>
                      <li>至少1个数字</li>
                      <li>至少1个符号</li>
                    </ul>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    label="重复新密码"
                    fullWidth={true}
                    type="password"
                    value={password.confirm}
                    onChange={(e) =>
                      setPassword((prevState) => ({ ...prevState, confirm: e.target.value }))
                    }
                    required
                    error={!!passwordError.confirm}
                    helperText={passwordError.confirm}
                  />
                </Grid>
                <Grid item container xs={12} justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Button label="完成" variant="primary" fullWidth={true} />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default ResetPassword;
