import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';

import { useStyles } from './styles';

const CustomTable = ({
  tableHeaders = [],
  tableData = [],
  columnAlign = [],
  columnWidth = [],
  tableHeaderStyle,
  rowOnClick,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeaders?.map((header, index) => (
              <TableCell
                key={header}
                className={`${classes.tableHeader} ${tableHeaderStyle}`}
                style={{ width: columnWidth[index] || '' }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow
                hover
                key={index}
                className={rowOnClick && classes.tableData}
                onClick={() => {
                  if (rowOnClick) {
                    rowOnClick(index);
                  }
                }}
              >
                {row.map((data, index) => (
                  <TableCell key={`${data}${index}`} align={columnAlign[index] || 'left'}>
                    {data}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="td"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes.table}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomTable.propTypes = {
  tableHeaders: PropTypes.array,
  tableData: PropTypes.array,
  columnAlign: PropTypes.array,
  columnWidth: PropTypes.array,
  rowOnClick: PropTypes.func,
  tableHeaderStyle: PropTypes.string,
};

export default CustomTable;
