import API_ROUTES from '../config/apiRoutes';
import { openModal } from '../redux/modal/actions';
import { onSpinner, offSpinner } from '../redux/spinner/actions';
import { apiRequest } from '../utils/axios';

export const getAllActivities = (type) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.activities.getActivity };
    // Only retrieve master class activities
    config.params = { type };

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const getActivity = (id) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.activities.getActivity };
    config.url = `${API_ROUTES.activities.getActivity.url}/${id}`;

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    if (error.status !== 404) {
      dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const createActivity = (activity, details, type, callback) => {
  return async (dispatch) => {
    try {
      dispatch(onSpinner());
      // Get API route and put data in payload
      const config = { ...API_ROUTES.activities.createActivity };
      // Convert data to required request format
      config.headers = {
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
      formData.append('type', type);
      formData.append('activityDate', activity.activityDate);
      formData.append('name', activity.name);
      const convertedDetails = [];
      details.forEach((detail) => {
        if (detail.image || detail.description) {
          convertedDetails.push({ image: false, description: detail.description });
          if (detail.image) {
            formData.append('images', detail.image);
            convertedDetails[convertedDetails.length - 1].image = true;
          }
        }
      });
      formData.append('details', JSON.stringify(convertedDetails));
      config.data = formData;

      await apiRequest(config);

      dispatch(
        openModal({
          type: 'success',
          title: '成功',
          message: `成功添加${type === 'masterclass' ? '课程' : '活动'}。`,
          primaryButtonCallback: callback,
        }),
      );
    } catch (error) {
      dispatch(openModal({ type: 'error', title: '错误', message: '无法提交，请稍后再试。' }));
    } finally {
      dispatch(offSpinner());
    }
  };
};

export const editActivity = (id, activity, details, callback) => {
  return async (dispatch) => {
    try {
      dispatch(onSpinner());
      // Get API route and put data in payload
      const config = { ...API_ROUTES.activities.updateActivity };
      config.url = `${API_ROUTES.activities.updateActivity.url}/${id}`;
      // Convert data to required request format
      config.headers = {
        'Content-Type': 'multipart/form-data',
      };
      const formData = new FormData();
      formData.append('activityDate', activity.activityDate);
      formData.append('name', activity.name);
      const convertedDetails = [];
      details.forEach((detail) => {
        if (detail.description || detail.image || detail.imageName) {
          convertedDetails.push({ image: '', description: detail.description, newImage: false });
          if (detail.image) {
            formData.append('images', detail.image);
            convertedDetails[convertedDetails.length - 1].newImage = true;
          } else {
            convertedDetails[convertedDetails.length - 1].image = detail.imageName;
          }
        }
      });
      formData.append('details', JSON.stringify(convertedDetails));
      config.data = formData;

      await apiRequest(config);

      dispatch(
        openModal({
          type: 'success',
          title: '成功',
          message: '保存成功。',
          primaryButtonCallback: callback,
        }),
      );
    } catch (error) {
      dispatch(openModal({ type: 'error', title: '错误', message: '无法提交，请稍后再试。' }));
    } finally {
      dispatch(offSpinner());
    }
  };
};

export const deleteActivity = (id, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.activities.deleteActivity };
    config.url = `${API_ROUTES.activities.deleteActivity.url}/${id}`;

    await apiRequest(config);

    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '删除成功。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    if (error.status !== 404) {
      dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};
