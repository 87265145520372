import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
  viewButton: {
    backgroundColor: 'rgb(49, 108, 244)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(49, 108, 244, 0.8)',
    },
  },
}));
