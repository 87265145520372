import React from 'react';
import { TextField as TextInput } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const TextField = ({
  label,
  variant = 'outlined',
  fullWidth = false,
  type = 'text',
  required = false,
  value = '',
  onChange,
  placeholder = '',
  name = '',
  pattern = '',
  error = false,
  helperText = '',
  multiline = false,
  rows = 1,
  disabled = false,
  backgroundColor = 'transparent',
  helperTextStyle,
  max,
  min,
}) => {
  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      name={name}
      variant={variant}
      fullWidth={fullWidth}
      type={type}
      required={required}
      value={value}
      onChange={onChange}
      InputLabelProps={type === 'date' ? { shrink: true } : {}}
      inputProps={{ pattern: pattern || undefined, max, min }}
      error={error}
      helperText={helperText}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      style={{ backgroundColor }}
      FormHelperTextProps={{ className: helperTextStyle }}
    />
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  pattern: PropTypes.string,
  dateFormat: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  helperTextStyle: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
};

export default TextField;
