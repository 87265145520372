import React, { useState } from 'react';
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Hello from '../../images/Hello.jpg';
import { useStyles } from './styles';
import TextField from '../shared/TextField';
import Button from '../shared/Button';
import { sendContactUsMessage } from '../../api/contactUs';

const INITIAL_STATE = {
  name: '',
  phoneNum: '',
  email: '',
  state: '',
  message: '',
};

const ContactUs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [contactUsDetails, setContactUsDetails] = useState(INITIAL_STATE);

  const handleInputChange = (e) => {
    setContactUsDetails((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendContactUsMessage(contactUsDetails));
  };

  return (
    <>
      <div className={classes.imgContainer}>
        <img src={Hello} className={classes.helloImage} />
      </div>
      <Container maxWidth="lg" className={classes.mainContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" component="span" className={classes.heading}>
              联络我们
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              温馨提醒：如果您有意愿加入我们团队，请致电 <a href="">018-9694510</a>{' '}
              协商履历与面试详情
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.formContainer} spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="姓名"
                type="text"
                name="name"
                value={contactUsDetails.name}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="联络号码"
                type="text"
                name="phoneNum"
                value={contactUsDetails.phoneNum}
                onChange={handleInputChange}
                pattern="^(\+?6?01)[0-46-9]-*[0-9]{7,8}$"
                placeholder="012-1234567"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="电子邮件"
                type="email"
                name="email"
                value={contactUsDetails.email}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              {/* <TextField
                label="标题"
                type="text"
                name="subject"
                value={contactUsDetails.subject}
                onChange={handleInputChange}
                fullWidth
                required
              /> */}
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="state-label">地区</InputLabel>
                <Select
                  labelId="state-label"
                  id="state"
                  value={contactUsDetails.state}
                  name="state"
                  onChange={handleInputChange}
                  label="地区"
                >
                  <MenuItem value="" disabled>
                    地区
                  </MenuItem>
                  <MenuItem value="Johor">Johor</MenuItem>
                  <MenuItem value="Kedah">Kedah</MenuItem>
                  <MenuItem value="Kelantan">Kelantan</MenuItem>
                  <MenuItem value="Malacca">Malacca</MenuItem>
                  <MenuItem value="Negeri Sembilan">Negeri Sembilan</MenuItem>
                  <MenuItem value="Pahang">Pahang</MenuItem>
                  <MenuItem value="Penang">Penang</MenuItem>
                  <MenuItem value="Perak">Perak</MenuItem>
                  <MenuItem value="Perlis">Perlis</MenuItem>
                  <MenuItem value="Selangor">Selangor</MenuItem>
                  <MenuItem value="Terrenganu">Terrenganu</MenuItem>
                  <MenuItem value="Sabah">Sabah</MenuItem>
                  <MenuItem value="Sarawak">Sarawak</MenuItem>
                  <MenuItem value="Kuala Lumpur">Kuala Lumpur</MenuItem>
                  <MenuItem value="Labuan">Labuan</MenuItem>
                  <MenuItem value="Putrajaya">Putrajaya</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="信息内容"
                type="text"
                name="message"
                value={contactUsDetails.message}
                onChange={handleInputChange}
                rows={5}
                multiline
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button label="提交" variant="black" />
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default ContactUs;
