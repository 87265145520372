import API_ROUTES from '../config/apiRoutes';
import { openModal } from '../redux/modal/actions';
import { offSpinner, onSpinner } from '../redux/spinner/actions';
import { apiRequest } from '../utils/axios';

export const getAllCareers = () => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.careers.getCareer };

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const getCareer = (id) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.careers.getCareer };
    config.url = `${API_ROUTES.careers.getCareer.url}/${id}`;

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const createCareer = (career, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.careers.createCareer };
    // Remove empty qualifications
    config.data = {
      ...career,
      qualification: career.qualification.filter((qualification) => qualification !== ''),
    };

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '成功添加应聘。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '无法提交，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const editCareer = (id, career, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.careers.updateCareer };
    config.url = `${API_ROUTES.careers.updateCareer.url}/${id}`;
    // Remove empty qualifications
    config.data = {
      ...career,
      qualification: career.qualification.filter((qualification) => qualification !== ''),
    };

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '保存成功。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '无法提交，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const deleteCareer = (id, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.careers.deleteCareer };
    config.url = `${API_ROUTES.careers.deleteCareer.url}/${id}`;

    await apiRequest(config);

    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '删除成功。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};
