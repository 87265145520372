import { SPINNER_ON, SPINNER_OFF } from './types';

export const onSpinner = () => {
  return {
    type: SPINNER_ON,
  };
};

export const offSpinner = () => {
  return {
    type: SPINNER_OFF,
  };
};
