import React, { useEffect, useState } from 'react';
import { Container, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';

import { useStyles } from './styles';
import Button from '../../shared/Button';
import TextField from '../../shared/TextField';
import { INITIAL_STATE } from './utils';
import { updateAccount } from '../../../api/user';
import { getLoggedInUser } from '../../../redux/user/actions';

const UserProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);

  const [userDetails, setUserDetails] = useState({ ...INITIAL_STATE });
  const [emailValidation, setEmailValidation] = useState('');

  useEffect(() => {
    if (!user.loggedIn) {
      dispatch(getLoggedInUser()).then((user) => {
        if (!user) {
          history.replace('/auth');
        }
      });
    } else {
      setUserDetails({
        email: user.details.email,
        name: user.details.name,
        birthDate: moment(user.details.birthDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        phoneNum: user.details.phoneNum,
        gender: user.details.gender,
        job: user.details.job,
        educationLevel: user.details.educationLevel,
      });
    }
  }, [dispatch, history, user]);

  const handleInputChange = (e) => {
    setUserDetails((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateAccount(userDetails, user.details.userId)).then((response) => {
      if (response === 'conflict') {
        setEmailValidation('该邮件已注册，请使用其他邮件');
      }
    });
  };

  return (
    <Container className={classes.mainContainer} maxWidth="lg">
      <Typography variant="h4" className={classes.pageTitle}>
        编辑个人资料
      </Typography>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container className={classes.formContainer} spacing={2}>
          <Grid item container xs={12} md={6} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                邮件：
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              <TextField
                label=""
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleInputChange}
                backgroundColor="white"
                fullWidth
                error={!!emailValidation}
                helperText={emailValidation}
                helperTextStyle={classes.errorHelperText}
                required
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                姓名：
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              <TextField
                label=""
                type="text"
                name="name"
                value={userDetails.name}
                onChange={handleInputChange}
                backgroundColor="white"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                生日：
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              <TextField
                label=""
                type="date"
                name="birthDate"
                value={userDetails.birthDate}
                onChange={handleInputChange}
                max={moment().format('YYYY-MM-DD')}
                backgroundColor="white"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                联络号码：
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              <TextField
                label=""
                type="text"
                name="phoneNum"
                value={userDetails.phoneNum}
                onChange={handleInputChange}
                backgroundColor="white"
                fullWidth
                pattern="^(\+?6?01)[0-46-9]-*[0-9]{7,8}$"
                placeholder="012-1234567"
                required
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                性别
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={userDetails.gender}
                  onChange={handleInputChange}
                  name="gender"
                  required
                  className={classes.select}
                >
                  <MenuItem value="male">男</MenuItem>
                  <MenuItem value="female">女</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                职业：
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              <TextField
                label=""
                type="text"
                name="job"
                value={userDetails.job}
                onChange={handleInputChange}
                backgroundColor="white"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <Grid item xs={5} md={3} lg={2}>
              <Typography variant="body1" component="span">
                学历
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} lg={10}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={userDetails.educationLevel}
                  onChange={handleInputChange}
                  name="educationLevel"
                  required
                  className={classes.select}
                >
                  <MenuItem value="无学历">无学历</MenuItem>
                  <MenuItem value="SPM">SPM</MenuItem>
                  <MenuItem value="STPM">STPM</MenuItem>
                  <MenuItem value="Diploma">Diploma</MenuItem>
                  <MenuItem value="Degree">Degree</MenuItem>
                  <MenuItem value="Master">Master</MenuItem>
                  <MenuItem value="PhD">PhD</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '8px' }}>
          <Grid item>
            <Button variant="primary" sharedClasses="normal-text" label="更新" />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default UserProfile;
