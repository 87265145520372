import API_ROUTES from '../config/apiRoutes';
import { openModal } from '../redux/modal/actions';
import { offSpinner, onSpinner } from '../redux/spinner/actions';
import { apiRequest } from '../utils/axios';

export const createPersonInCharge = (personInCharge, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.personInCharge.createPersonInCharge };
    config.data = personInCharge;

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '成功添加负责人。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    // Error 409 indicates that email already been registered
    if (error.status === 409) {
      return 'conflict';
    } else {
      dispatch(openModal({ type: 'error', title: '错误', message: '添加失败，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const editPersonInCharge = (id, personInCharge, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.personInCharge.updatePersonInCharge };
    config.url = `${API_ROUTES.personInCharge.updatePersonInCharge.url}/${id}`;
    // Remove empty qualifications
    config.data = personInCharge;

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '保存成功。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    // Error 409 indicates that email already been registered
    if (error.status === 409) {
      return 'conflict';
    } else {
      dispatch(openModal({ type: 'error', title: '错误', message: '添加失败，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const getPersonInCharge = (id) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.personInCharge.getPersonInCharge };
    config.url = `${API_ROUTES.personInCharge.getPersonInCharge.url}/${id}`;

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const getAllPersonInCharge = () => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.personInCharge.getPersonInCharge };

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const deletePersonInCharge = (id, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.personInCharge.deletePersonInCharge };
    config.url = `${API_ROUTES.personInCharge.deletePersonInCharge.url}/${id}`;

    await apiRequest(config);

    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '删除成功。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};
