export const questionList = [
  {
    0: {
      answer: '富于冒险 Adventure:愿意面对新事物并敢于下决心掌握的人',
      type: 'D',
    },
    1: {
      answer: '适应力强 Adaptability: 轻松自如适应任何环境',
      type: 'S',
    },
    2: {
      answer: '生动 Vivid:充满活力,表情生动,多手势',
      type: 'I',
    },
    3: {
      answer: '善于分析 Analysis: 喜欢研究各部分之间的逻辑和正确的关系',
      type: 'C',
    },
  },
  {
    0: {
      answer: '坚持不懈 Persevere：要完成现有的事才能做新的事情',
      type: 'C',
    },
    1: {
      answer: '喜好娱乐 Entertainment：开心充满乐趣与幽默感',
      type: 'I',
    },
    2: {
      answer: '善于说服 Persuasion：用逻辑和事实而不用威严和权利服人',
      type: 'D',
    },
    3: {
      answer: '平和 Peaceful：在冲突中不受干扰，保持平静',
      type: 'S',
    },
  },
  {
    0: {
      answer: '顺服 Obedient：易接受他人的观点和喜好，不坚持己见',
      type: 'S',
    },
    1: {
      answer: '自我牺牲 Self-sacrificing：为他人利益愿意放弃个人意见',
      type: 'C',
    },
    2: {
      answer: '善于社交 Sociable：认为与人相处是好玩，而不是挑战或者商业机会',
      type: 'I',
    },
    3: {
      answer: '意志坚定 Strong-willed：决心以自己的方式做事',
      type: 'D',
    },
  },
  {
    0: {
      answer: '使人认同 Recognizable：因人格魅力或性格使人认同',
      type: 'I',
    },
    1: {
      answer: '体贴 Considerate：关心别人的感受与需要',
      type: 'C',
    },
    2: {
      answer: '竞争性 Competitiveness：把一切当作竞赛，总是有强烈的赢的欲望',
      type: 'D',
    },
    3: {
      answer: '自控性 Self-control：控制自己的情感，极少流露',
      type: 'S',
    },
  },
  {
    0: { answer: '使人振作 Cheer up：给他人清新振奋的刺激 ', type: 'I' },
    1: { answer: '尊重他人 Respective：对人诚实尊重 ', type: 'C' },
    2: { answer: '善于应变 Adaptable：对任何情况都能作出有效的反应 ', type: 'D' },
    3: { answer: '含蓄 Implicit：自我约束情绪与热忱 ', type: 'S' },
  },
  {
    0: { answer: '生机勃勃 Vibrant：充满生命力与兴奋 ', type: 'I' },
    1: { answer: '满足 Satisfy：容易接受任何情况与环境 ', type: 'S' },
    2: { answer: '敏感 Sensitive：对周围的人事过分关心 ', type: 'C' },
    3: { answer: '自立 Self-reliance：独立性强，只依靠自己的能力、判断、与才智 ', type: 'D' },
  },
  {
    0: { answer: '计划者 Planner：先做详尽的计划，并严格要计划进行，不想改动 ', type: 'C' },
    1: { answer: '耐性 Patience：不因延误而懊恼，冷静且能容忍 ', type: 'S' },
    2: { answer: '积极 Positive：相信自己有转危为安的能力 ', type: 'D' },
    3: { answer: '推动者 Promoter：动用性格魅力或鼓励别人参与 ', type: 'I' },
  },
  {
    0: { answer: '肯定 Affirmation：自信，极少犹豫或者动摇 ', type: 'D' },
    1: { answer: '无拘无束 Unrestrained：不喜欢预先计划，或者被计划牵制 ', type: 'I' },
    2: { answer: '羞涩 Bashful：安静，不善于交谈 ', type: 'S' },
    3: { answer: '有时间性 Timely：生活处事依靠时间表，不喜欢计划被人干扰 ', type: 'C' },
  },
  {
    0: { answer: '迁就 Accommodate：改变自己以与他人协调，短时间内按他人要求行事 ', type: 'S' },
    1: { answer: '井井有条 Well-organized：有系统有条理安排事情的人 ', type: 'C' },
    2: { answer: '坦率 Frank：毫无保留，坦率发言 ', type: 'I' },
    3: { answer: '乐观 Optimism：令他人和自己相信任何事情都会好转 ', type: 'D' },
  },
  {
    0: { answer: '强迫性 Compulsive：发号施令，强迫他人听从 ', type: 'D' },
    1: { answer: '忠诚 Loyalty：一贯可靠，忠心不移，有时毫无根据地奉献 ', type: 'C' },
    2: { answer: '有趣 Interesting：风趣，幽默，把任何事物都能变成精彩的故事 ', type: 'I' },
    3: { answer: '友善 Friendly：不主动交谈，不爱争论 ', type: 'S' },
  },
  {
    0: { answer: '勇敢 Bravery：敢于冒险，无所畏惧 ', type: 'D' },
    1: { answer: '体贴 Considerate：待人得体，有耐心 ', type: 'S' },
    2: { answer: '注意细节 Attentive：观察入微，做事情有条不紊 ', type: 'C' },
    3: { answer: '可爱 Adorable：开心，与他人相处充满乐趣 ', type: 'I' },
  },
  {
    0: { answer: '令人开心 Cheerful：充满活力，并将快乐传于他人 ', type: 'I' },
    1: {
      answer: '文化修养 Cultural Accomplishment：对艺术学术特别爱好，如戏剧、交响乐 ',
      type: 'C',
    },
    2: { answer: '自信 Self-confidence：确信自己个人能力与成功 ', type: 'D' },
    3: { answer: '贯彻始终 Follow through：情绪平稳，做事情坚持不懈 ', type: 'S' },
  },
  {
    0: { answer: '理想主义 Idealism：以自己完美的标准来设想衡量新事物 ', type: 'C' },
    1: { answer: '独立 Independence：自给自足，独立自信，不需要他人帮忙 ', type: 'D' },
    2: { answer: '无攻击性 Non-aggresive：不说或者做可能引起别人不满和反对的事情 ', type: 'S' },
    3: { answer: '富有激励 Motivational：鼓励别人参与、加入，并把每件事情变得有趣 ', type: 'I' },
  },
  {
    0: {
      answer: '感情外露 Emotional exposure：从不掩饰情感.喜好,交谈时常身不由己接触他人 ',
      type: 'I',
    },
    1: { answer: '深沉 Profound：深刻并常常内省，对肤浅的交谈、消遣会厌恶 ', type: 'C' },
    2: { answer: '果断 Decisiveness：有很快做出判断与结论的能力 ', type: 'D' },
    3: { answer: '幽默 Humor：语气平和而有冷静的幽默 ', type: 'S' },
  },
  {
    0: { answer: '调解者 Mediator：经常居中调节不同的意见，以避免双方的冲突 ', type: 'S' },
    1: {
      answer: '音乐性 Musicality：爱好参与并有较深的鉴赏能力，因音乐的艺术性,而不是因为表演的乐趣 ',
      type: 'C',
    },
    2: { answer: '发起人 Initiator：高效率的推动者，是他人的领导者，闲不住 ', type: 'D' },
    3: { answer: '喜交朋友 Sociable：喜欢周旋聚会中，善交新朋友不把任何人当陌生人 ', type: 'I' },
  },
  {
    0: { answer: '考虑周到 Considerate：善解人意，帮助别人，记住特别的日子 ', type: 'C' },
    1: { answer: '执着 Perseverance：不达目的，誓不罢休 ', type: 'D' },
    2: {
      answer: '多言 Talkative：不断的说话、讲笑话以娱乐他人，觉得应该避免沉默而带来的的尴尬 ',
      type: 'I',
    },
    3: { answer: '容忍 Tolerance：易接受别人的想法和看法，不需要反对或改变他人 ', type: 'S' },
  },
  {
    0: { answer: '聆听者 Listener：愿意听别人倾诉 ', type: 'S' },
    1: { answer: '忠心对自己的理想、朋友、工作都绝对忠实，有时甚至不需要理由 Loyalty ', type: 'C' },
    2: { answer: '领导者 Leaders：天生的领导，不相信别人的能力能比上自己 ', type: 'D' },
    3: { answer: '活力充沛 Vitality：充满活力，精力充沛 ', type: 'I' },
  },
  {
    0: { answer: '知足 Contentment：满足自己拥有的，很少羡慕别人 ', type: 'S' },
    1: { answer: '首领 Leader：要求领导地位及别人跟随 ', type: 'D' },
    2: { answer: '制图者 Cartographer：用图表数字来组织生活，解决问题 ', type: 'C' },
    3: { answer: '惹人喜爱 Likable：人们注意的中心，令人喜欢 ', type: 'I' },
  },
  {
    0: { answer: '完美主义者 Perfectionists：对自己、对别人都高标准、一切事物有秩序 ', type: 'C' },
    1: { answer: '和气 Harmony：易相处，易说话，易让人接近 ', type: 'S' },
    2: { answer: '勤劳 Diligent：不停的工作，完成任务，不愿意休息 ', type: 'D' },
    3: { answer: '受欢迎 Popular：聚会时的灵魂人物，受欢迎的宾客 ', type: 'I' },
  },
  {
    0: { answer: '跳跃性 Non-linear thinking：充满活力和生气勃勃 ', type: 'I' },
    1: { answer: '无畏 Fearless：大胆前进，不怕冒险 ', type: 'D' },
    2: { answer: '规范性 Regulative：时时坚持自己的举止合乎认同的道德规范 ', type: 'C' },
    3: { answer: '平衡 Balance：稳定，走中间路线 ', type: 'S' },
  },
  {
    0: { answer: '乏味 Boring：死气沉沉，缺乏生气 ', type: 'S' },
    1: { answer: '忸怩 Coy：躲避别人的注意力，在众人注意下不自然 ', type: 'C' },
    2: { answer: '露骨 Explicit：好表现，华而不实，声音大 ', type: 'I' },
    3: { answer: '专横 Domineering：喜命令支配，有时略显傲慢 ', type: 'D' },
  },
  {
    0: { answer: '散漫 Laxity：生活任性无秩序 ', type: 'I' },
    1: { answer: '无同情心 No Empathy：不易理解别人的问题和麻烦 ', type: 'D' },
    2: { answer: '缺乏热情 Lack of Enthusiasm：不易兴奋，经常感到好事难做 ', type: 'S' },
    3: { answer: '不宽恕 Unforgiveness：不易宽恕和忘记别人对自己的伤害，易嫉妒 ', type: 'C' },
  },
  {
    0: { answer: '保留 Reservation：不愿意参与，尤其是当事情复杂时 ', type: 'S' },
    1: { answer: '怨恨 Resentment：把实际或者自己想象的别人的冒犯经常放在心中 ', type: 'C' },
    2: { answer: '逆反 Rebellion：抗拒、或者拒不接受别人的方法，固执己见 ', type: 'D' },
    3: {
      answer: '唠叨 Nagging：重复讲同一件事情或故事，忘记已经重复多次，总是不断找话题说话 ',
      type: 'I',
    },
  },
  {
    0: { answer: '挑剔 Picky：坚持琐事细节，总喜欢挑不足 ', type: 'C' },
    1: { answer: '胆小 Timid：经常感到强烈的担心焦虑、悲戚 ', type: 'S' },
    2: { answer: '健忘 Forgetfulness：缺乏自我约束，导致健忘，不愿意回忆无趣的事情 ', type: 'I' },
    3: { answer: '率直 Straightforward：直言不讳，直接表达自己的看法 ', type: 'D' },
  },
  {
    0: { answer: '没耐性 Impatient：难以忍受等待别人 ', type: 'D' },
    1: { answer: '无安全感 Insecurity：感到担心且无自信心 ', type: 'S' },
    2: { answer: '优柔寡断 Indecisive：很难下决定 ', type: 'C' },
    3: {
      answer: '好插嘴 Good Interjection：一个滔滔不绝的发言人，不是好听众，不注意别人的说话 ',
      type: 'I',
    },
  },
  {
    0: { answer: '不受欢迎 Unpopular：由于强烈要求完美，而拒人千里 ', type: 'C' },
    1: { answer: '不参与 Not participating：不愿意加入，不参与，对别人生活不感兴趣 ', type: 'S' },
    2: { answer: '难预测 Unpredictable：时而兴奋，时而低落，或总是不兑现诺言 ', type: 'I' },
    3: { answer: '缺同情心 Lack of empathy：很难当众表达对弱者或者受难者的情感 ', type: 'D' },
  },
  {
    0: { answer: '固执 Stubborness：坚持照自己的意见行事，不听不同意见 ', type: 'D' },
    1: { answer: '随兴 Random：做事情没有一贯性，随意做事情 ', type: 'I' },
    2: { answer: '难于取悦 Difficult to please：因为要求太高而使别人很难取悦 ', type: 'C' },
    3: { answer: '行动迟缓 Slow Action：迟迟才行动，不易参与或者行动总是慢半拍 ', type: 'S' },
  },
  {
    0: { answer: '平淡 Plain：平实淡漠，中间路线,无高低之分，很少表露情感 ', type: 'S' },
    1: { answer: '悲观 Pessimistic：尽管期待最好但往往首先看到事物不利之处 ', type: 'C' },
    2: { answer: '自负 Self-confidence：自我评价高，认为自己是最好的人选 ', type: 'D' },
    3: {
      answer: '放任 Indulge:许别人做他喜欢做的事情，为的是讨好别人，令别人鼓吹自己 ',
      type: 'I',
    },
  },
  {
    0: { answer: '易怒 Irritability：善变，孩子性格，易激动，过后马上就忘了 ', type: 'I' },
    1: { answer: '无目标 Aimless：不喜欢目标，也无意订目标 ', type: 'S' },
    2: { answer: '好争论 Arguing：易与人争吵，不管对何事都觉得自己是对的 ', type: 'D' },
    3: {
      answer: '孤芳自赏 Insecure：容易感到被疏离，经常没有安全感或担心别人不喜欢和自己相处 ',
      type: 'C',
    },
  },
  {
    0: { answer: '天真 Innocence：孩子般的单纯，不理解生命的真谛 ', type: 'I' },
    1: { answer: '消极 Negative：往往看到事物的消极面阴暗面，而少有积极的态度 ', type: 'C' },
    2: { answer: '鲁莽 Reckless：充满自信有胆识但总是不恰当 ', type: 'D' },
    3: { answer: '冷漠 Indifference：漠不关心，得过且过 ', type: 'S' },
  },
  {
    0: { answer: '担忧 Anxious：时时感到不确定、焦虑、心烦 ', type: 'S' },
    1: { answer: '不善交际 Not Sociable:总喜欢挑人毛病，不被人喜欢 ', type: 'C' },
    2: {
      answer:
        '工作狂 Workaholic:为了回报或者说成就感，而不是为了完美，因而设立雄伟目标不断工作，耻于休息 ',
      type: 'D',
    },
    3: { answer: '喜获认同 Recognized：需要旁人认同赞赏，像演员 ', type: 'I' },
  },
  {
    0: { answer: '过分敏感 Oversensitive：对事物过分反应，被人误解时感到被冒犯 ', type: 'C' },
    1: { answer: '不圆滑老练 Tactless：经常用冒犯或考虑不周的方式表达自己 ', type: 'D' },
    2: { answer: '胆怯 Timidity：遇到困难退缩 ', type: 'S' },
    3: { answer: '喋喋不休 Chattering：难以自控，滔滔不绝，不能倾听别人 ', type: 'I' },
  },
  {
    0: { answer: '腼腆 Bashful：事事不确定，对所做的事情缺乏信心 ', type: 'S' },
    1: { answer: '生活紊乱 Disordered life：缺乏安排生活的能力 ', type: 'I' },
    2: { answer: '跋扈 Domineering：冲动的控制事物和别人，指挥他人 ', type: 'D' },
    3: { answer: '抑郁 Depression：常常情绪低落 ', type: 'C' },
  },
  {
    0: {
      answer: '缺乏毅力 Lack of Perseverance：反复无常，互相矛盾，情绪与行动不合逻辑 ',
      type: 'I',
    },
    1: { answer: '内向 Introvert：活在自己的世界里，思想和兴趣放在心里 ', type: 'C' },
    2: { answer: '不容忍 Intolerance：不能忍受他人的观点、态度和做事的方式 ', type: 'D' },
    3: { answer: '无异议 No objection：对很多事情漠不关心 ', type: 'S' },
  },
  {
    0: { answer: '杂乱无章 Disorganized：生活环境无秩序，经常找不到东西 ', type: 'I' },
    1: { answer: '情绪化 Emotional：情绪不易高涨，感到不被欣赏时很容易低落 ', type: 'C' },
    2: { answer: '喃喃自语 Mumbling：低声说话，不在乎说不清楚 ', type: 'S' },
    3: { answer: '喜操纵 Manipulation：精明处事，操纵事情，使对自己有利 ', type: 'D' },
  },
  {
    0: { answer: '缓慢 Tardy：行动思想均比较慢，过分麻烦 ', type: 'S' },
    1: { answer: '顽固 Stubborn：决心依自己的意愿行事，不易被说服 ', type: 'D' },
    2: { answer: '好表现 Performance：要吸引人，需要自己成为被人注意的中心 ', type: 'I' },
    3: { answer: '有戒心 Be Wary：不易相信，对语言背后的真正的动机存在疑问 ', type: 'C' },
  },
  {
    0: { answer: '孤僻 Withdrawn：需要大量的时间独处，避开人群 ', type: 'C' },
    1: { answer: '统治欲 Desire to dominate：毫不犹豫地表示自己的正确或控制能力 ', type: 'D' },
    2: { answer: '懒惰 Laziness：总是先估量事情要耗费多少精力，能不做最好 ', type: 'S' },
    3: { answer: '大嗓门 Loud Voice：说话声和笑声总盖过他人 ', type: 'I' },
  },
  {
    0: { answer: '拖延 Procrastination：凡事起步慢，需要推动力 ', type: 'S' },
    1: { answer: '多疑 Suspicious：凡事怀疑，不相信别人 ', type: 'C' },
    2: { answer: '易怒 Irritability：对行动不快或不能完成指定工作时易烦躁和发怒 ', type: 'D' },
    3: { answer: '不专注 Inattentive：无法专心致志或者集中精力 ', type: 'I' },
  },
  {
    0: { answer: '报复性 Retaliation：记恨并惩罚冒犯自己的人 ', type: 'C' },
    1: { answer: '烦躁 Irritability：喜新厌旧，不喜欢长时间做相同的事情 ', type: 'I' },
    2: { answer: '勉强 Reluctance：不愿意参与或者说投入 ', type: 'S' },
    3: { answer: '轻率 Hasty：因没有耐心，不经思考，草率行动 ', type: 'D' },
  },
  {
    0: { answer: '妥协 Compromise：为避免矛盾即使自己是对的也不惜放弃自己的立场 ', type: 'S' },
    1: { answer: '好批评 Criticism：不断地衡量和下判断，经常考虑提出反对意见 ', type: 'C' },
    2: { answer: '狡猾 Cunning：精明，总是有办法达到目的 ', type: 'D' },
    3: { answer: '善变 Changeable：像孩子般注意力短暂，需要各种变化，怕无聊 ', type: 'I' },
  },
];
