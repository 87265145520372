import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import styles from './styles.module.css';
import logo from '../../../images/logo-white.png';

const Footer = () => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  return (
    <>
      <div className={`${isPortrait ? 'block' : ''} ${styles['footer-container']}`}>
        <div className={styles['community-container']}>
          <Link to="/">
            <img className={styles['footerimg']} src={logo} />
          </Link>
          <p className={styles['social-media-container']}>
            <a href="https://www.facebook.com/MDRT510/">
              <i className={`fab fa-facebook-square ${styles['facebook']}`}></i>
            </a>
            <a href="https://instagram.com/smartgmy">
              <i className={`fab fa-instagram ${styles['instagram']}`}></i>
            </a>
            <a href="https://wa.link/pxnu96">
              <i className={`fab fa-whatsapp ${styles['whatsapp']}`}></i>
            </a>
          </p>
        </div>
        <div
          className={`${isPortrait ? styles['padding-none'] : ''} 
          ${styles['navigation-container']}`}
        >
          <Link to="/service" className={styles['hyperlink']}>
            服务
          </Link>
          {/* <Link to="/event" className={styles['hyperlink']}>
            活动
          </Link> */}
          <Link to="/masterclass" className={styles['hyperlink']}>
            课程
          </Link>
          <Link to="/about" className={styles['hyperlink']}>
            关于
          </Link>
        </div>
        <div
          className={`${isPortrait ? styles['padding-none'] : ''} 
          ${styles['navigation-container']}`}
        >
          <Link to="/contact-us" className={styles['hyperlink']}>
            联络
          </Link>
          <Link to="/career" className={styles['hyperlink']}>
            Join Us
          </Link>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  sharedClasses: PropTypes.string,
};

export default Footer;
