import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const QuestionCard = ({ question, number, userAnswers, setAnswers, headerFont, contentFont }) => {
  const onChangeHandler = (e) => {
    const index = e.target.value;
    setAnswers((prevState) => {
      const newState = [...prevState];
      newState[number - 1] = question[index].type;
      return newState;
    });
  };
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  return (
    <div className={styles['card-container']}>
      <div className={`${styles['header']} ${styles[headerFont]}`}>
        {number}.&nbsp;&nbsp;我是一个 什么样的人？
      </div>
      <hr style={{ margin: 0, color: 'rgb(110,110,110)' }} />
      <div
        className={`${isMobileScreen && isPortrait ? styles['content-container-straight'] : ''} ${
          styles['content-container']
        } ${styles[contentFont]}`}
      >
        {Object.entries(question).map(([key, { answer }], index) => (
          <React.Fragment key={index}>
            <label>
              <input
                type="radio"
                name={number}
                value={key}
                onChange={onChangeHandler}
                checked={userAnswers[number - 1] === question[key].type}
              />
              <span>{answer}</span>
              <br />
            </label>
            <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

QuestionCard.propTypes = {
  number: PropTypes.number,
  userAnswers: PropTypes.array,
  question: PropTypes.object,
  setAnswers: PropTypes.func,
  headerFont: PropTypes.string,
  contentFont: PropTypes.string,
};

export default QuestionCard;
