import React from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

const Spinner = () => {
  const classes = useStyles();
  const open = useSelector((state) => state.spinner.open);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress />
    </Backdrop>
  );
};

export default Spinner;
