import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const NotFound = () => {
  const classes = useStyles();
  const isSubDomain = window.location.hostname.includes('disc.smartg.my');

  return (
    <Grid
      container
      className={classes.mainContainer}
      alignItems="center"
      justifyContent="center"
      direction="column"
      style={{ minHeight: isSubDomain ? '100vh' : 'calc(100vh - 90px)' }}
    >
      <Typography
        variant="h1"
        component="h1"
        className={classes.errorHeading}
        align="center"
        gutterBottom={true}
      >
        404
      </Typography>
      <Typography variant="h1" component="h2" className={classes.errorDesc} align="center">
        找不到您访问的页面
      </Typography>
    </Grid>
  );
};

export default NotFound;
