import { PASSWORD_REGEX } from '../../../config/constants';

export const INITIAL_STATE = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const validateData = (password) => {
  const errorState = { ...INITIAL_STATE };

  if (password.currentPassword.length === 0) {
    errorState.currentPassword = '请把当前密码填上';
  }

  if (!PASSWORD_REGEX.test(password.newPassword)) {
    errorState.newPassword = '密码必须符合规格';
  } else if (password.newPassword !== password.confirmNewPassword) {
    errorState.confirmNewPassword = '密码必须一致';
  }

  const hasError = Object.values(errorState).some((value) => value !== '');

  return { errorState, hasError };
};
