import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import TextField from '../../shared/TextField';
import Button from '../../shared/Button';
import { INITIAL_STATE, validateData } from './utils';
import { getLoggedInUser } from '../../../redux/user/actions';
import { updatePassword } from '../../../api/user';

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);

  const [password, setPassword] = useState({ ...INITIAL_STATE });
  const [validationState, setValidationState] = useState({ ...INITIAL_STATE });

  useEffect(() => {
    if (!user.loggedIn) {
      dispatch(getLoggedInUser()).then((user) => {
        if (!user) {
          history.replace('/auth');
        }
      });
    }
  }, [dispatch, history, user]);

  const handleInputChange = (e) => {
    setPassword((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { errorState, hasError } = validateData(password);

    setValidationState(errorState);
    if (!hasError) {
      dispatch(updatePassword(password, user.details.userId)).then((response) => {
        if (response === 'unauthorized') {
          setValidationState({ ...errorState, currentPassword: '当前密码错误' });
        } else {
          setPassword({ ...INITIAL_STATE });
        }
      });
    }
  };

  return (
    <Container className={classes.mainContainer} maxWidth="sm">
      <Typography variant="h4" className={classes.pageTitle}>
        更改密码
      </Typography>
      <Grid
        container
        direction="column"
        className={classes.formContainer}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item container alignItems="center">
          <Grid item xs={5} md={4} lg={3}>
            <Typography variant="body1" component="span">
              当前密码：
            </Typography>
          </Grid>
          <Grid item xs={7} md={8} lg={9}>
            <TextField
              label=""
              type="password"
              name="currentPassword"
              value={password.currentPassword}
              onChange={handleInputChange}
              backgroundColor="white"
              fullWidth
              error={!!validationState.currentPassword}
              helperText={validationState.currentPassword}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={4} lg={3}>
            <Typography variant="body1" component="span">
              新密码：
            </Typography>
          </Grid>
          <Grid item xs={7} md={8} lg={9}>
            <TextField
              label=""
              type="password"
              name="newPassword"
              value={password.newPassword}
              onChange={handleInputChange}
              backgroundColor="white"
              fullWidth
              error={!!validationState.newPassword}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={4} lg={3}></Grid>
          {validationState.newPassword && (
            <Grid item xs={7} md={8} lg={9} style={{ color: 'red', fontSize: '0.8rem' }}>
              密码必须符合以下规格：
              <ul>
                <li>至少8个字符</li>
                <li>至少1个大写字母</li>
                <li>至少1个小写字母</li>
                <li>至少1个数字</li>
                <li>至少1个符号</li>
              </ul>
            </Grid>
          )}
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={4} lg={3}>
            <Typography variant="body1" component="span">
              重复新密码：
            </Typography>
          </Grid>
          <Grid item xs={7} md={8} lg={9}>
            <TextField
              label=""
              type="password"
              name="confirmNewPassword"
              value={password.confirmNewPassword}
              onChange={handleInputChange}
              backgroundColor="white"
              fullWidth
              error={!!validationState.confirmNewPassword}
              helperText={validationState.confirmNewPassword}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '8px' }}>
        <Grid item>
          <Button
            variant="primary"
            sharedClasses="normal-text"
            label="提交"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChangePassword;
