import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';

import { useStyles } from './styles';
import { getAllActivities } from '../../../../api/activity';
import CustomTable from '../../../shared/Table';

const ActivityTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isMasterclass = location.pathname.includes('masterclass');

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    dispatch(getAllActivities(isMasterclass ? 'masterclass' : 'event')).then((allActivities) => {
      if (allActivities) {
        setActivities(allActivities);
      }
    });
  }, [dispatch, isMasterclass]);

  const tableHeaders = isMasterclass ? ['课程名称', '课程日期', ''] : ['活动名称', '活动日期', ''];
  const tableData = activities?.map((activity) => [
    activity.name,
    moment(activity.activityDate).format('DD-MM-YYYY'),
    <Button
      variant="contained"
      key={`${activity._id}-button`}
      className={classes.editButton}
      onClick={() => {
        history.push(`/admin/${isMasterclass ? 'masterclass' : 'events'}/edit/${activity._id}`);
      }}
    >
      <EditIcon fontSize="small" />
    </Button>,
  ]);
  const columnAlign = ['', '', 'center'];
  const columnWidth = ['50%', '25%', '25%'];

  return (
    <Container className={classes.mainContainer} maxWidth="lg">
      <Typography variant="h4" className={classes.pageTitle}>
        {isMasterclass ? '课程' : '活动'}
      </Typography>
      <Grid container className={classes.tableContainer}>
        <CustomTable
          tableHeaders={tableHeaders}
          tableData={tableData}
          columnAlign={columnAlign}
          columnWidth={columnWidth}
        />
      </Grid>
    </Container>
  );
};

export default ActivityTable;
