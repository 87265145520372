import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Container, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import TextField from '../../../shared/TextField';
import Button from '../../../shared/Button';

import { INITIAL_STATE, validateData } from './utils';
import { openModal } from '../../../../redux/modal/actions';
import {
  createPersonInCharge,
  deletePersonInCharge,
  editPersonInCharge,
  getPersonInCharge,
} from '../../../../api/personInCharge';

const AddPersonInCharge = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  const [personInCharge, setPersonInCharge] = useState({ ...INITIAL_STATE });
  const [validationState, setValidationState] = useState({ ...INITIAL_STATE });

  useEffect(() => {
    if (id) {
      dispatch(getPersonInCharge(id)).then((response) => {
        if (response) {
          setPersonInCharge({ name: response.name, email: response.email });
        } else {
          dispatch(
            openModal({
              type: 'error',
              title: '错误',
              message: `找不到该负责人资料。`,
              primaryButtonCallback: () => history.replace(`/admin/person-in-charge/edit`),
            }),
          );
        }
      });
    } else {
      setPersonInCharge({ ...INITIAL_STATE });
      setValidationState({ ...INITIAL_STATE });
    }
  }, [dispatch, history, id]);

  const handleSubmit = () => {
    const { errorState, hasError } = validateData(personInCharge);
    setValidationState(errorState);

    if (!hasError) {
      if (id) {
        dispatch(
          editPersonInCharge(id, personInCharge, () => {
            history.push(`/admin/person-in-charge/edit`);
          }),
        ).then((response) => {
          if (response === 'conflict') {
            setValidationState({ ...errorState, email: '该邮件已被使用，请使用其他邮件' });
          }
        });
      } else {
        dispatch(
          createPersonInCharge(personInCharge, () => {
            history.push(`/admin/person-in-charge/edit`);
          }),
        ).then((response) => {
          if (response === 'conflict') {
            setValidationState({ ...errorState, email: '该邮件已被使用，请使用其他邮件' });
          }
        });
      }
    }
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        type: 'warning',
        title: '注意',
        message: `确定要删除此负责人？`,
        primaryButtonCallback: () => {
          dispatch(
            deletePersonInCharge(id, () => {
              history.push(`/admin/person-in-charge/edit`);
            }),
          );
        },
        primaryButtonText: '确定',
        secondaryButtonText: '取消',
      }),
    );
  };

  return (
    <Container className={classes.mainContainer} maxWidth="sm">
      <Typography variant="h4" className={classes.pageTitle}>
        {id ? '更改负责人' : '添加负责人'}
      </Typography>
      <Grid
        container
        direction="column"
        className={classes.formContainer}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              姓名：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <TextField
              label=""
              type="text"
              name="name"
              value={personInCharge.name}
              onChange={(e) =>
                setPersonInCharge((prevState) => ({ ...prevState, name: e.target.value }))
              }
              backgroundColor="white"
              fullWidth
              error={!!validationState.name}
              helperText={validationState.name}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              电子邮件
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <TextField
              label=""
              type="email"
              name="email"
              value={personInCharge.email}
              onChange={(e) =>
                setPersonInCharge((prevState) => ({ ...prevState, email: e.target.value }))
              }
              backgroundColor="white"
              fullWidth
              error={!!validationState.email}
              helperText={validationState.email}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={id ? 'space-between' : 'flex-end'}
        spacing={2}
        style={{ marginTop: '8px' }}
      >
        {id && (
          <Grid item>
            <Button
              variant="danger"
              sharedClasses="normal-text"
              label="删除负责人"
              onClick={handleDelete}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            variant="primary"
            sharedClasses="normal-text"
            label={id ? '保存' : '提交'}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddPersonInCharge;
