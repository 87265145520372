import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router } from 'react-router-dom';

import Route from './Route';
import { mainTheme } from './styles/theme';

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <Router>
        <Route />
      </Router>
    </ThemeProvider>
  );
}

export default App;
