import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  imgContainer: {
    height: '50vh',
    width: '100%',
    overflow: 'hidden',
  },
  helloImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  mainContainer: {
    padding: theme.spacing(2),
    width: '100%',
  },
  heading: {
    paddingBottom: theme.spacing(1),
    borderBottom: '3px solid black',
  },
  description: {
    marginTop: theme.spacing(3),
  },
  formContainer: {
    marginTop: theme.spacing(4),
  },
}));
