import React from 'react';

import styles from './styles.module.css';

const RotateDevice = () => {
  return (
    <>
      <div className={styles['rotate-container']}>
        {/* <img src={RotateImage} /> */}
        <div className={styles['positioning']}>
          <div className={styles['phone']}></div>
        </div>
        <div className={styles['rotate-text']}>
          <span>请把手机打横以得到更舒服的观看体验</span>
        </div>
      </div>
    </>
  );
};

export default RotateDevice;
