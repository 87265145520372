import React from 'react';
import { useMediaQuery } from 'react-responsive';

import styles from './styles.module.css';
import Profile from '../Profile';
import { profileList } from './profileList';
import groupPhoto from '../../images/smartg-team.png';

const About = () => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1250px)' });
  const narrowScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  return (
    <>
      <div className={styles['about-container']}>
        <div className={styles['info-div']}>
          {/* <span className={styles['en-title']}>| SMARTg</span> */}
          <div className={styles['image-container']}>
            <img src={groupPhoto} />
          </div>
          <p>
            {/* <b>SMARTg</b> 于2010年在柔佛地区成立。直到2017年，我们开发至槟城地区。从那以来，
            <b>SMARTg</b> 都在为柔佛, 槟城和吉隆坡各大地区提供卓越的财务顾问服务。<b>SMARTg</b>
            与众不同的是，规划员的年龄介于 25岁- 35岁之间，
            但年资最深的都已经超过10年了。通过市场与客户需求，我们优秀的专家团队会为大众量身定制符合他们预算的财务规划服务。
            <b>SMARTg</b> 的一切都始于一班有梦想开始新事物的年轻人。他们开创金融理财，数码寿险事业。
            <br />
            <br />据<b>SMARTg</b> 创办人<b>Ms Goh Ee Lin</b>
            那里得知，<b>SMARTg</b>
            有一个伟大的愿景，就是大家对金融理财数码寿险的改变充满热情，同时持续地寻找最佳的解决方案和提供卓越服务的公司。为了应对不断变化和快速增长的财富管理需求，我们的顾问不断扩展和发展他们的专业知识，以便客户能够享受最好的服务。
            <br />
            <br />
            欲了解更多信息，您可以浏览我们的网站或直接与我们联系。 */}
            <b style={{ fontWeight: 700 }}>SMARTg</b> 于 2010 年在柔佛地区成立。 直到 2017
            年，我们开发至槟城地区。 从那以来，
            <b style={{ fontWeight: 700 }}>SMARTg</b> &nbsp; 都在为柔佛，槟城和吉隆坡,
            各大地区提供1对1的财务顾问服务。
            <br />
            <br />
            <b style={{ fontWeight: 700 }}>SMARTg</b> 与众不同的是， 规划员的年龄介于 25 岁 至 35
            岁之间， 但年资最深的都已经超过 10 年了。 通过市场与客户需求，我们优秀的专家团队
            会为大众量身定制符合他们预算的财务规划服务。
            <br />
            <br />
            <b style={{ fontWeight: 700 }}>SMARTg</b> 的一切始于一班有理想打稳金融理财根基的年轻人。
            我们经营金融理财，数码寿险以及遗产分配的安排。
            <br />
            <br />据 <b style={{ fontWeight: 700 }}>SMARTg</b> 创办人{' '}
            <b style={{ fontWeight: 700 }}>Ms Goh Ee Lin</b> 那里得知，{' '}
            <b style={{ fontWeight: 700 }}>SMARTg</b> 有一个伟大的愿景，
            就是大家对金融理财数码寿险的改变充满热情，
            同时持续地寻找最佳的解决方案和提供卓越服务的公司。
            为了应对不断变化和快速增长的财富管理需求， 我们的顾问不断扩展和发展他们的专业知识，
            以便客户能够享受最好的服务。
            <br />
            <br />
            欲了解更多信息，您可以浏览我们的网站或直接与我们联系。
            <br />
            <br />
            <hr />
            <br />
            <b style={{ fontWeight: 700 }}>SMARTg</b> was established in 2010 around the Johor area.
            In the year of 2017, we developed to the Penang area. Since then,{' '}
            <b style={{ fontWeight: 700 }}>SMARTg</b> has been providing outstanding financial
            advisory services to major regions in Johor, Penang and Kuala Lumpur.
            <br />
            <br />
            What makes <b style={{ fontWeight: 700 }}>SMARTg</b> unique is that all the financial
            planners are between 25 and 35 years old, but the oldest ones are more than 10 years
            old. Throughout the market and customer needs, our excellent team of experts will tailor
            financial planning services to meet their budgets for the public.
            <br />
            <br />
            Everything at <b style={{ fontWeight: 700 }}>SMARTg</b> begins with a group of young
            people who have dreams of starting new things. They created financial management and
            digital life insurance business...
            <br />
            <br />
            According to the founder of <b style={{ fontWeight: 700 }}>SMARTg</b>,{' '}
            <b style={{ fontWeight: 700 }}>Ms Goh Ee Lin</b>,{' '}
            <b style={{ fontWeight: 700 }}>SMARTg</b> has a great vision, that is, everyone is
            passionate about the changes in financial management and digital life insurance, while
            continuing to find the best solutions and providing excellent services company. In
            response to the ever-changing and rapidly growing wealth management needs, our
            consultants continue to expand and develop their expertise so that clients can enjoy the
            best services in <b style={{ fontWeight: 700 }}>SMARTg</b>.
            <br />
            <br />
            For more information, you can browse our website or contact us directly.
          </p>
        </div>
        <div id="members" className={`${styles['team-div']} ${isMobileScreen ? 'block' : ''}`}>
          <div className={`${styles['left-title-div']} ${isMobileScreen ? styles['pb-40'] : ''}`}>
            <span className={styles['ch-title']}>| 团队</span>
          </div>
          <div className={`${styles['right-content-div']}`}>
            {isPortrait ? (
              <>
                {profileList.map((profile, index) => (
                  <Profile
                    alt={profile.alt}
                    image={profile.mobileImage}
                    name={profile.name}
                    info={profile.info}
                    infoEng={profile.infoEng}
                    absoluteURI={profile.absoluteURI}
                    key={index}
                  ></Profile>
                ))}
              </>
            ) : (
              <>
                {profileList.map((profile, index) => (
                  <Profile
                    alt={profile.alt}
                    image={profile.image}
                    name={profile.name}
                    info={profile.info}
                    infoEng={profile.infoEng}
                    absoluteURI={profile.absoluteURI}
                    key={index}
                  ></Profile>
                ))}
              </>
            )}

            {narrowScreen ? '' : <div className={styles['filler']}></div>}
          </div>
        </div>
        {/* <div className={`${styles['value-div']} ${isMobileScreen ? 'block' : ''}`}>
          <div className={`${styles['left-title-div']} ${isMobileScreen ? styles['pb-40'] : ''}`}>
            <span className={styles['ch-title']}>| 理念</span>
          </div>
          <div className={`${styles['right-content-div']}`}>
            <div className={styles['value-component']}>
              <p>01</p>
              {/* <p>愿景</p>
              <p></p>
              <p>成为大马顶尖 20%，并且提供卓越数码金融服务</p>
            </div>
            <div className={styles['value-component']}>
              <p>02</p>
              {/* <p>使命</p>
              <p></p>
              <p>传递理财规划的重要性，帮组客户寻找解决方案让生活更精彩</p>
            </div>
            <div className={styles['value-component']}>
              <p>03</p>
              {/* <p>使命</p>
              <p></p>
              <p>提供专业理财解决方案，拿走担忧以及完成梦想，持续为客户创造最大价值</p>
            </div>
            <div className={styles['value-component']}>
              <p>04</p>
              {/* <p>使命</p>
              <p></p>
              <p>激发与培养高素质的年轻人，让大众转移财务风险与建立财富安全网</p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default About;
