import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import styles from './styles.module.css';
import banner from '../../images/service-banner.jpeg';
import Button from '../shared/Button';
// import s1 from '../../images/s1.jpg';
// import s2 from '../../images/test-service.jpg';
// import s3 from '../../images/s3.jpg';
// import s4 from '../../images/s4.jpeg';
import s1 from '../../images/service-1.png';
import s2 from '../../images/service-2.png';
import s3 from '../../images/service-3.png';
import s4 from '../../images/service-4.png';

const Service = () => {
  //   const isPortrait = useMediaQuery({ orientation: 'portrait' });
  //   const isShortDevice = useMediaQuery({ maxHeight: '500px' });
  const isSmallDevice = useMediaQuery({ maxWidth: '910px' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  return (
    <>
      <div className={styles['service-container']}>
        <div className={styles['banner-container']}>
          <img src={banner} />
          <p>
            <span>|</span> 服务
          </p>
        </div>
        {/* <div className={styles['service-component-container']}>
          <div className={styles['row']}>
            <div id="financial" className={styles['card-container']}>
              <div className={styles['card']}>
                <div className={styles['card-front']}>
                  <img src={s1} />
                  <p>财务规划</p>
                </div>
                <div className={styles['card-back']}>
                  <p className={styles['small-text']}>制定计划与管理支出</p>
                  <p className={styles['big-text']}>财务规划</p>
                  <p className={styles['normal-text']}>
                    协助客户制定个人预算和储蓄。
                    <br />
                    目标 避免，保留，减少和转移风险。
                    <br />
                    实施风险管理和税务规划策略。
                  </p>
                  <div>
                    <Button
                      label="联络专员"
                      onClick={() => (window.location.href = '/about#members')}
                      variant="btn-contact-us slideshow-margin"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>

            <div id="wealth" className={styles['card-container']}>
              <div className={styles['card']}>
                <div className={styles['card-front']}>
                  <img src={s2} />
                  <p>财富累积</p>
                </div>
                <div className={styles['card-back']}>
                  <p className={styles['small-text']}>管理与抵御风险</p>
                  <p className={styles['big-text']}>财富累积</p>
                  <p className={styles['normal-text']}>
                    帮助客户规划财产和理财。
                    <br />
                    从储蓄到退休计划的财务目标。
                    <br />
                    控制和管理费用。
                  </p>
                  <div>
                    <Button
                      label="联络专员"
                      onClick={() => (window.location.href = '/about#members')}
                      variant="btn-contact-us slideshow-margin"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="test" className={styles['row']}>
            <div id="will" className={styles['card-container']}>
              <div className={styles['card']}>
                <div className={styles['card-front']}>
                  <img src={s3} />
                  <p>遗产规划</p>
                </div>
                <div className={styles['card-back']}>
                  <p className={styles['small-text']}>财富传承与规划</p>
                  <p className={styles['big-text']}>遗产规划</p>
                  <p className={styles['normal-text']}>
                    提供会计，财务和法律建议，帮助他们为临终和继承问题做好准备。
                    <br />
                    做好传承分配，简化繁琐的手续。
                    <br />
                    确保根据死者的意愿分散资产并偿还债务。
                  </p>
                  <div>
                    <Button
                      label="联络专员"
                      onClick={() => (window.location.href = '/about#members')}
                      variant="btn-contact-us slideshow-margin"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>

            <div id="group" className={styles['card-container']}>
              <div className={styles['card']}>
                <div className={styles['card-front']}>
                  <img src={s4} />
                  <p>
                    公司员工
                    <br />
                    团体保险
                  </p>
                </div>
                <div className={styles['card-back']}>
                  <p className={styles['small-text']}>保障员工权益</p>
                  <p className={styles['big-text']}>公司员工团体保险</p>
                  <p className={styles['normal-text']}>
                    为公司员工提供可自定义的健康计划。
                    <br />
                    奖励健康习惯帮助员工保持工作效率。
                    <br />
                    专为中小企业设计的综合性团体保险计划，其中包括人寿和医疗。
                  </p>
                  <div>
                    <Button
                      label="联络专员"
                      onClick={() => (window.location.href = '/about#members')}
                      variant="btn-contact-us slideshow-margin"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles['service-image']}>
          <div className={styles['services']}>
            <div className={`${styles['row']} ${isPortrait ? '' : styles['mt-5']}`}>
              <img src={s1}></img>
              <img src={s2}></img>
            </div>
            <div className={`${styles['row']} ${isPortrait ? '' : styles['mt-5']}`}>
              <img src={s3}></img>
              <img src={s4}></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
