export const adminSidebarMenu = {
  // Event is temporarily removed
  // events: {
  //   label: '编辑活动',
  //   subMenu: {
  //     create: {
  //       label: '添加活动',
  //       to: '/admin/events/create',
  //     },
  //     edit: {
  //       label: '更改活动',
  //       to: '/admin/events/edit',
  //     },
  //   },
  // },
  masterclass: {
    label: '编辑活动/课程',
    subMenu: {
      create: {
        label: '添加活动/课程',
        to: '/admin/masterclass/create',
      },
      edit: {
        label: '更改活动/课程',
        to: '/admin/masterclass/edit',
      },
    },
  },
  // careers: {
  //   label: '编辑应聘',
  //   subMenu: {
  //     create: {
  //       label: '添加应聘',
  //       to: '/admin/careers/create',
  //     },
  //     edit: {
  //       label: '更改应聘',
  //       to: '/admin/careers/edit',
  //     },
  //   },
  // },
  registrations: {
    label: '批准课程报名',
    to: '/admin/registrations',
  },
  disc: {
    label: 'DISC测试记录',
    to: '/admin/disc',
  },
  pic: {
    label: '管理负责人',
    subMenu: {
      create: {
        label: '添加负责人',
        to: '/admin/person-in-charge/create',
      },
      edit: {
        label: '更改负责人',
        to: '/admin/person-in-charge/edit',
      },
    },
  },
};

export const userSidebarMenu = {
  profile: {
    label: '编辑个人资料',
    to: '/user/profile',
  },
  password: {
    label: '更改密码',
    to: '/user/password',
  },
  registrations: {
    label: '课程',
    to: '/user/registrations',
  },
};
