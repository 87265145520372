import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Modal } from '@material-ui/core';
import moment from 'moment';

import styles from './styles.module.css';
import questionStyles from '../QuestionCard/styles.module.css';
import TextField from '../shared/TextField';
import Button from '../shared/Button';
// import logoblack from '../../images/logo-black.png';
import banner from '../../images/disc-banner.png';
import intro from '../../images/disc-intro.png';
import introMobile from '../../images/disc-intro-mobile.png';
import QuestionCard from '../QuestionCard';
import { questionList } from './questionList';
import { createDISCResult } from '../../api/disc';
import { openModal } from '../../redux/modal/actions';
import DiscResult from '../DiscResult';
import { getAllPersonInCharge } from '../../api/personInCharge';

const INITIAL_STATE = {
  email: '',
  name: '',
  phoneNum: '',
  birthDate: '',
  gender: '',
  result: {},
  personInCharge: '',
};

const DISC = () => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState(INITIAL_STATE);
  const [answers, setAnswers] = useState(new Array(40).fill(''));
  const [answerErrors, setAnswerErrors] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [disc, setDisc] = useState({ D: 0, I: 0, S: 0, C: 0 });
  const [PICs, setPICs] = useState([]);

  const isMobileScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isSubDomain = window.location.hostname.includes('disc.smartg.my');

  useEffect(() => {
    dispatch(getAllPersonInCharge()).then((allPersonInCharge) => {
      if (allPersonInCharge) {
        setPICs(allPersonInCharge);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (!isSubDomain) {
      // Redirect to subdomain if user visits smartg.my/disc
      document.location.replace('https://disc.smartg.my');
    }
  }, [isSubDomain]);

  const showFormHandler = () => {
    const missingAnswers = [];
    answers.forEach((answer, index) => {
      if (answer === '') {
        missingAnswers.push(index + 1);
      }
    });
    if (missingAnswers.length > 0) {
      dispatch(openModal({ title: '错误', type: 'error', message: '请把所以问题回答后再提交' }));
      setAnswerErrors(missingAnswers);
    } else {
      setAnswerErrors([]);
      setShowForm(true);
    }
  };

  const handleInputChange = (e) => {
    setUserInfo((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createDISCResult(userInfo, answers));
    setShowForm(false);
    generateGraph();
    setGenerated(true);
  };
  const generateGraph = () => {
    var d = 0;
    var i = 0;
    var s = 0;
    var c = 0;

    for (const type of answers) {
      if (type == 'D') {
        d += 1;
      }
      if (type == 'I') {
        i += 1;
      }
      if (type == 'S') {
        s += 1;
      }
      if (type == 'C') {
        c += 1;
      }
    }
    setDisc({ D: d, I: i, S: s, C: c });
  };

  return (
    <>
      <div className={styles['disc-container']}>
        {/* <img className={styles['background-image']} src={background} /> */}
        <img className={styles['banner']} src={banner} />
        {isPortrait ? (
          <img className={styles['banner']} src={introMobile} />
        ) : (
          <img className={styles['banner']} src={intro} />
        )}
        {/* <img className={styles['logo-center']} src={logoblack} /> */}
        {generated ? (
          <DiscResult D={disc.D} I={disc.I} S={disc.S} C={disc.C}></DiscResult>
        ) : (
          <>
            {/* <div className={questionStyles['card-container']}>
              <div
                className={`${questionStyles['header']} ${questionStyles['roboto']}`}
                style={{ fontWeight: 900 }}
              >
                DISC Personality Test
              </div>
              <hr style={{ margin: 0, color: 'rgb(110,110,110)' }} />
              <div className={questionStyles['content-container']}>
                <div>
                  性格测试非常重要，无论是在管理过程中还是在日程生活过程都是如此。
                  <br />
                  DISC的行为测评技术现广泛运用在企业招聘环节，也是招聘者们最常用也最为重要的测评工具之一，
                  提前了解候选人的行为风格。
                  <br />
                  同时，作为团队管理者，通过DISC判断自己与团队成员的不同，
                  理解不同人的思维特质和行为模式，判断双方在价值取向、核心动力的差异，在了解这些后，
                  可更好的建立沟通和职场伙伴关系。
                  <br />
                  <br />
                  DISC個性测验四個英文字母分別代表以下性格：
                  <br />
                  <span className={`${styles['initial-indent']} ${styles['red']}`}>D</span>
                  <span className={`${styles['text-indent']} ${styles['red']}`}>：Dominance</span>
                  <span className={styles['red']}>(支配型)</span>
                  <br />
                  <span className={`${styles['initial-indent']} ${styles['orange']}`}>I</span>
                  <span className={`${styles['text-indent']} ${styles['orange']}`}>
                    ：Influence
                  </span>
                  <span className={styles['orange']}>(影响型)</span>
                  <br />
                  <span className={`${styles['initial-indent']} ${styles['green']}`}>S</span>
                  <span className={`${styles['text-indent']} ${styles['green']}`}>
                    ：Steadiness
                  </span>
                  <span className={styles['green']}>(稳定型)</span>
                  <br />
                  <span className={`${styles['initial-indent']} ${styles['blue']}`}>C</span>
                  <span className={`${styles['text-indent']} ${styles['blue']}`}>：Compliance</span>
                  <span className={styles['blue']}>(分析型)</span>
                  <br />
                  <br />
                  以下是DISC性格测试题，看看自己是属于哪一种人格特质。
                  <br />
                  特别注意：请按第一印象最快的选择，如果不能确定，可回忆童年时的情况，或者以你最熟悉的人对你的评价来从中选择。
                  <br />
                  测验时间：10分钟。
                </div>
              </div>
            </div> */}
            {questionList.map((question, index) => (
              <QuestionCard
                key={index}
                question={question}
                number={index + 1}
                userAnswers={answers}
                setAnswers={setAnswers}
                headerFont={isMobileScreen ? 'header-sm' : ''}
                contentFont={isMobileScreen ? 'content-sm' : ''}
              />
            ))}
            {answerErrors.length > 0 && (
              <Alert severity="error" style={{ margin: '-4% auto 0 auto', width: '80vw' }}>
                请在列出的题目中做出选择：
                {answerErrors.map((answerError, index) => {
                  if (index === answerErrors.length - 1) {
                    return answerError;
                  }
                  return `${answerError}, `;
                })}
              </Alert>
            )}
            <div style={{ width: '80vw', height: '50px', margin: '-3% auto auto auto' }}>
              <Button label="提交" variant="primary float-right" onClick={showFormHandler} />
            </div>
          </>
        )}
      </div>
      {showForm && (
        <Modal open={showForm} onClose={() => setShowForm(false)}>
          <form className={styles['disc-form']} onSubmit={handleSubmit}>
            <div style={{ marginTop: '-10px', marginBottom: '40px', fontSize: '18px' }}>
              以下个人信息信息仅用于计算测试结果和个性化测试报告。
            </div>
            <TextField
              label="姓名"
              fullWidth={true}
              required={true}
              type="text"
              name="name"
              value={userInfo.name}
              onChange={handleInputChange}
            />
            <br />
            <br />
            <div>
              <select
                required="required"
                name="gender"
                value={userInfo.gender}
                onChange={handleInputChange}
              >
                <option disabled="disabled" value="">
                  性别
                </option>
                <option value="Male">男</option>
                <option value="Female">女</option>
              </select>
            </div>
            <br />
            <TextField
              label="生日"
              fullWidth={true}
              required={true}
              type="date"
              name="birthDate"
              value={userInfo.birthDate}
              onChange={handleInputChange}
              max={moment().format('YYYY-MM-DD')}
            />
            <br />
            <br />
            <TextField
              label="联络号码"
              fullWidth={true}
              required={true}
              type="text"
              name="phoneNum"
              value={userInfo.phoneNum}
              onChange={handleInputChange}
              pattern="^(\+?6?01)[0-46-9]-*[0-9]{7,8}$"
              placeholder="012-1234567"
            />
            <br />
            <br />
            <TextField
              label="电子邮件"
              fullWidth={true}
              required={true}
              type="email"
              name="email"
              value={userInfo.email}
              onChange={handleInputChange}
              pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$"
              placeholder="e.g. smartg@gmail.com"
            />
            <br />
            <br />
            <div>
              <select
                required="required"
                name="personInCharge"
                value={userInfo.personInCharge}
                onChange={handleInputChange}
              >
                <option disabled="disabled" value="">
                  负责人
                </option>
                {PICs.map((pic) => (
                  <option value={pic._id} key={pic._id}>
                    {pic.name}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <Button
              label="确定"
              variant="primary float-right"
              containerWidth="100%"
              containerMargin="20px auto auto auto"
            />
            <div style={{ width: '80vw', height: '50px', margin: '-3% auto auto auto' }}>
              <Button
                label="取消"
                variant="danger float-right"
                containerWidth="100%"
                containerMargin="20px auto auto auto"
                onClick={() => setShowForm(false)}
              />
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default DISC;
