import { makeStyles } from '@material-ui/core';
import JoinUs from '../../images/JoinUs.jpg';

export const useStyles = makeStyles((theme) => ({
  coverImage: {
    width: '100%',
    height: '50vh',
    backgroundImage: `url(${JoinUs})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
  },
  joinUs: {
    color: 'white',
    paddingLeft: theme.spacing(2),
    marginLeft: '10%',
    borderLeft: '5px solid white',
    position: 'absolute',
    top: '40%',
    fontWeight: '500',
  },
  smartg: {
    fontSize: '50px',
    fontWeight: 500,
    margin: theme.spacing(4, 0),
  },
  introduction: {
    fontSize: '20px',
  },
  jobNameContainer: {
    marginTop: theme.spacing(4),
  },
  jobNameFrame: {
    border: '1px solid #D9D9D9',
    padding: theme.spacing(2),
    cursor: 'pointer',
    borderRadius: '50px',
    margin: theme.spacing(1),
  },
  selectedJob: {
    backgroundColor: '#606060',
    color: 'white',
  },
  jobDescriptionContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(6),
    backgroundColor: '#EFEFEF',
  },
  qualificationContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: '#F2D3AC',
    color: '#333333',
  },
  qualification: {
    fontSize: '20px',
  },
  image: {
    width: '100%',
    marginBottom: '-4px',
  },
}));
