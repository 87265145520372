import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  formContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
  errorHelperText: {
    backgroundColor: '#F6F6F6',
    margin: 0,
    color: '#F44336',
    fontSize: 12,
  },
  imageContainer: {
    height: '150px',
    width: '100%',
    borderRadius: '5px',
    border: '2px dashed rgb(128, 128, 128)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  errorBorder: {
    borderColor: '#F44336',
  },
  uploadIcon: {
    color: 'rgb(128, 128, 128)',
    fontSize: '40px',
  },
  uploadButton: {
    background: 'red',
  },
  activityImage: {
    width: '100%',
    maxHeight: '300px',
    objectFit: 'contain',
  },
}));
