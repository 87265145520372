import { DeviceUUID } from 'device-uuid';

import { apiRequest } from '../utils/axios';
import API_ROUTES from '../config/apiRoutes';
import { offSpinner, onSpinner } from '../redux/spinner/actions';
import * as AccessTokenController from '../utils/accessToken';
import { getLoggedInUser, logout } from '../redux/user/actions';
import { store } from '../redux/store';

export const login = (credentials, callback) => {
  return async (dispatch) => {
    try {
      dispatch(onSpinner());

      // Send API request to login
      const config = { ...API_ROUTES.auth.login };
      const deviceId = new DeviceUUID().get();
      config.data = { ...credentials, deviceId };
      config.withCredentials = true;
      const response = await apiRequest(config);

      // Set access token to local memory
      if (!response.accessToken) {
        throw new Error('登入失败，请稍后再试');
      }
      AccessTokenController.setAccessToken(response.accessToken);
      // Populate user data in redux
      dispatch(getLoggedInUser());

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(logout());
      if (error?.status === 401) {
        return '电子邮件/密码错误';
      } else {
        return '登入失败，请稍后再试';
      }
    } finally {
      dispatch(offSpinner());
    }
  };
};

export const refreshAccessToken = async () => {
  try {
    // Get API route and put data in payload
    const config = { ...API_ROUTES.auth.refreshToken };
    config.withCredentials = true;
    const response = await apiRequest(config);
    return response;
  } catch (error) {
    store.dispatch(logout(true));
    throw error;
  }
};
