import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

import { closeModal } from '../../../redux/modal/actions';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';

const Modal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);

  const closeHandler = () => {
    if (modal.secondaryButtonCallback) {
      modal.secondaryButtonCallback();
    } else if (modal.primaryButtonCallback && !modal.secondaryButtonText) {
      modal.primaryButtonCallback();
    }
    dispatch(closeModal());
  };

  const primaryButtonHandler = () => {
    if (modal.primaryButtonCallback) {
      modal.primaryButtonCallback();
    }
    dispatch(closeModal());
  };

  return (
    <Dialog open={modal.isOpen} classes={{ paper: classes.dialog }} onClose={closeHandler}>
      <DialogTitle className={classes.dialogTitle}>
        {modal.type === 'success' && <CheckCircleIcon className={classes.successIcon} />}
        {modal.type === 'warning' && <WarningIcon className={classes.warningIcon} />}
        {modal.type === 'error' && <ErrorIcon className={classes.errorIcon} />}
        <Typography variant="h6" component="p" style={{ fontWeight: 700 }}>
          {modal.title}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="subtitle2" component="p">
          {modal.message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container spacing={3} justifyContent="center">
          {modal.secondaryButtonText && (
            <Grid item>
              <Button variant="contained" color="secondary" onClick={closeHandler}>
                {modal.secondaryButtonText}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color={modal.secondaryButtonText ? 'primary' : 'secondary'}
              className={modal.type === 'warning' ? classes.dangerButton : ''}
              onClick={primaryButtonHandler}
            >
              {modal.primaryButtonText || '关闭'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
