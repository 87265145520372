import axios from 'axios';
import { refreshAccessToken } from '../api/auth';
import API_ROUTES from '../config/apiRoutes';
import * as AccessTokenController from './accessToken';

export const apiRequest = async (config) => {
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      console.error(error.response.data);
      throw error.response.data;
    } else if (error.response) {
      console.error(error.response);
      throw error.response;
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const initiateAuthInterceptor = () => {
  // Intercept every API request and adds authorization header with token
  axios.interceptors.request.use(
    async (config) => {
      const accessToken = AccessTokenController.getAccessToken();
      if (accessToken) {
        config.headers['Authorization'] = accessToken;
      }
      return config;
    },
    async (error) => {
      throw error;
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config, response } = error;
      const excludedRoutes = [
        API_ROUTES.auth.login.url,
        API_ROUTES.auth.logout.url,
        API_ROUTES.auth.refreshToken.url,
      ];
      // Check whether should attempt to get new access token
      const needRefresh =
        response?.status === 401 && !config._retry && !excludedRoutes.includes(config.url);

      if (!needRefresh) {
        throw error;
      }

      config._retry = true;
      let convertedData = {};
      if (config.data) {
        try {
          convertedData = JSON.parse(config.data);
        } catch (error) {
          convertedData = config.data;
        }
      }
      // Attempt to get refresh token and make second request
      try {
        if (!AccessTokenController.getRefreshingFlag()) {
          // Use refreshing flag to prevent 2 parallel API requests attempt to refresh together
          AccessTokenController.setRefreshingFlag(true);
          // Make API request to get refresh token
          const response = await refreshAccessToken();
          if (!response.accessToken) {
            throw error;
          } else {
            AccessTokenController.setAccessToken(response.accessToken);
          }
        } else {
          while (AccessTokenController.getRefreshingFlag()) {
            await new Promise((resolve) =>
              setTimeout(() => resolve(console.log('Awaiting new access token...')), 300),
            );
          }
        }
        // Retry initial API request with new access token
        if (AccessTokenController.getAccessToken()) {
          const header = { Authorization: AccessTokenController.getAccessToken() };
          const newConfig = { ...config, data: convertedData, headers: header };
          return axios(newConfig);
        } else {
          throw error;
        }
      } catch (error) {
        AccessTokenController.setAccessToken('');
        throw error;
      } finally {
        AccessTokenController.setRefreshingFlag(false);
      }
    },
  );
};
