import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Button = ({ label, onClick, variant, fullWidth, sharedClasses }) => {
  let convertedVariant = '';
  variant.split(' ').forEach((value) => {
    if (value) {
      convertedVariant += styles[value] + ' ';
    }
  });

  return (
    <button
      className={`${convertedVariant} ${styles['general']} ${sharedClasses}`}
      onClick={onClick}
      style={{ width: fullWidth ? '100%' : '' }}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  sharedClasses: PropTypes.string,
};

export default Button;
