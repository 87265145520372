import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useStyles } from './styles';
import { INITIAL_STATE, validateData } from './utils';
import Button from '../../../shared/Button';
import TextField from '../../../shared/TextField';
import { createCareer, deleteCareer, editCareer, getCareer } from '../../../../api/career';
import { openModal } from '../../../../redux/modal/actions';

const AddCareer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  const [career, setCareer] = useState({ ...INITIAL_STATE });
  const [validationState, setValidationState] = useState({ ...INITIAL_STATE });

  useEffect(() => {
    if (id) {
      dispatch(getCareer(id)).then((response) => {
        if (!response) {
          dispatch(
            openModal({
              type: 'error',
              title: '错误',
              message: `找不到该应聘资料。`,
              primaryButtonCallback: () => history.replace(`/admin/careers/edit`),
            }),
          );
        } else {
          const { title, description, qualification } = response;
          setCareer({ title, description, qualification });
        }
      });
    } else {
      setCareer({ ...INITIAL_STATE });
      setValidationState({ ...INITIAL_STATE });
    }
  }, [dispatch, history, id]);

  const addQualification = () => {
    setCareer((prevState) => {
      const tempState = _.cloneDeep(prevState);
      tempState.qualification.push('');
      return tempState;
    });
  };

  const handleSubmit = () => {
    const { errorState, hasError } = validateData(career);
    setValidationState(errorState);

    if (!hasError) {
      if (id) {
        dispatch(
          editCareer(id, career, () => {
            history.push(`/admin/careers/edit`);
          }),
        );
      } else {
        dispatch(
          createCareer(career, () => {
            history.push(`/admin/careers/edit`);
          }),
        );
      }
    }
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        type: 'warning',
        title: '注意',
        message: `确定要删除此应聘？`,
        primaryButtonCallback: () => {
          dispatch(
            deleteCareer(id, () => {
              history.push(`/admin/careers/edit`);
            }),
          );
        },
        primaryButtonText: '确定',
        secondaryButtonText: '取消',
      }),
    );
  };

  return (
    <Container className={classes.mainContainer} maxWidth="sm">
      <Typography variant="h4" className={classes.pageTitle}>
        {id ? '更改应聘' : '添加应聘'}
      </Typography>
      <Grid
        container
        direction="column"
        className={classes.formContainer}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              应聘职位：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <TextField
              label=""
              type="text"
              name="title"
              value={career.title}
              onChange={(e) => setCareer((prevState) => ({ ...prevState, title: e.target.value }))}
              backgroundColor="white"
              fullWidth
              error={!!validationState.title}
              helperText={validationState.title}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              工作内容：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <TextField
              label=""
              type="text"
              name="description"
              value={career.description}
              onChange={(e) =>
                setCareer((prevState) => ({ ...prevState, description: e.target.value }))
              }
              backgroundColor="white"
              fullWidth
              multiline
              rows={3}
              error={!!validationState.description}
              helperText={validationState.description}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
        {career.qualification.map((qualification, index) => (
          <React.Fragment key={index}>
            <Grid item container alignItems="center">
              <Grid item xs={5} md={3} lg={2}>
                <Typography variant="body1" component="span">
                  申请条件：
                </Typography>
              </Grid>
              <Grid item xs={7} md={9} lg={10}>
                <TextField
                  label=""
                  type="text"
                  name="qualification"
                  value={qualification}
                  onChange={(e) =>
                    setCareer((prevState) => {
                      const tempState = _.cloneDeep(prevState);
                      tempState.qualification[index] = e.target.value;
                      return tempState;
                    })
                  }
                  backgroundColor="white"
                  fullWidth
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item container xs={12} justifyContent="flex-start">
          <Grid>
            <Button
              variant="btn-add-details"
              sharedClasses="normal-text"
              label="添加申请条件"
              onClick={addQualification}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={id ? 'space-between' : 'flex-end'}
        spacing={2}
        style={{ marginTop: '8px' }}
      >
        {id && (
          <Grid item>
            <Button
              variant="danger"
              sharedClasses="normal-text"
              label="删除应聘"
              onClick={handleDelete}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            variant="primary"
            sharedClasses="normal-text"
            label={id ? '保存' : '提交'}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddCareer;
