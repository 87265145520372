import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  formContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
  errorHelperText: {
    backgroundColor: '#F6F6F6',
    margin: 0,
  },
  select: {
    backgroundColor: 'white',
  },
}));
