import API_ROUTES from '../config/apiRoutes';
import { openModal } from '../redux/modal/actions';
import { offSpinner, onSpinner } from '../redux/spinner/actions';
import { apiRequest } from '../utils/axios';

export const sendContactUsMessage = (contactUsDetails) => {
  return async (dispatch) => {
    try {
      dispatch(onSpinner());
      // Get API route and put data in payload
      const config = { ...API_ROUTES.contactUs.sendMessage };
      config.data = contactUsDetails;

      await apiRequest(config);
      dispatch(
        openModal({
          type: 'success',
          title: '成功',
          message: '信息已发送。',
        }),
      );
    } catch (error) {
      dispatch(openModal({ type: 'error', title: '错误', message: '无法提交，请稍后再试。' }));
    } finally {
      dispatch(offSpinner());
    }
  };
};
