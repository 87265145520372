import React from 'react';
import { Backdrop } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const BankSlipPreview = ({ setOpenBankSlip, bankSlip }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true} onClick={() => setOpenBankSlip(false)}>
      <img src={bankSlip} alt="Bank Slip Preview" className={classes.bankSlip} />
    </Backdrop>
  );
};

BankSlipPreview.propTypes = {
  setOpenBankSlip: PropTypes.func.isRequired,
  bankSlip: PropTypes.string.isRequired,
};

export default BankSlipPreview;
