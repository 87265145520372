import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import spinnerReducer from './spinner/reducer';
import modalReducer from './modal/reducer';
import userReducer from './user/reducer';

let composeEnhancers = compose;

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const rootReducer = combineReducers({
  spinner: spinnerReducer,
  modal: modalReducer,
  user: userReducer,
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
