export const INITIAL_STATE = {
  title: '',
  description: '',
  qualification: [''],
};

export const validateData = (career) => {
  const errorState = { ...INITIAL_STATE };

  if (career.title.length === 0) {
    errorState.title = '请把应聘职位填上';
  }
  if (career.description.length === 0) {
    errorState.description = '请把工作内容填上';
  }

  const hasError = errorState.title !== '' || errorState.description !== '';

  return { errorState, hasError };
};
