import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './styles';
import { getAllActivities } from '../../../../api/activity';
import CustomTable from '../../../shared/Table';

const RegistrationTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [masterclass, setMasterclass] = useState([]);

  useEffect(() => {
    dispatch(getAllActivities('masterclass')).then((allMasterclass) => {
      if (allMasterclass) {
        setMasterclass(allMasterclass);
      }
    });
  }, [dispatch]);

  const tableHeaders = ['课程名称', '课程日期', ''];
  const tableData = masterclass?.map((activity) => [
    activity.name,
    moment(activity.activityDate).format('DD-MM-YYYY'),
    <Button
      variant="contained"
      key={`${activity._id}-button`}
      className={classes.viewButton}
      onClick={() => {
        history.push(`/admin/registrations/${activity._id}`);
      }}
    >
      <SearchIcon fontSize="small" />
    </Button>,
  ]);
  const columnAlign = ['', '', 'center'];
  const columnWidth = ['50%', '25%', '25%'];

  return (
    <Container className={classes.mainContainer} maxWidth="lg">
      <Typography variant="h4" className={classes.pageTitle}>
        课程
      </Typography>
      <Grid container className={classes.tableContainer}>
        <CustomTable
          tableHeaders={tableHeaders}
          tableData={tableData}
          columnAlign={columnAlign}
          columnWidth={columnWidth}
        />
      </Grid>
    </Container>
  );
};

export default RegistrationTable;
