import { produce } from 'immer';

import { OPEN_MODAL, CLOSE_MODAL } from './types';

const initialState = {
  isOpen: false,
  title: '',
  message: '',
  type: 'warning',
  primaryButtonText: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return produce(state, (draft) => {
        draft.isOpen = true;
        draft.title = action.payload.title;
        draft.message = action.payload.message;
        draft.type = action.payload.type;
        draft.primaryButtonText = action.payload.primaryButtonText;
        draft.primaryButtonCallback = action.payload.primaryButtonCallback;
        draft.secondaryButtonText = action.payload.secondaryButtonText;
        draft.secondaryButtonCallback = action.payload.secondaryButtonCallback;
      });
    case CLOSE_MODAL:
      return produce(state, (draft) => {
        draft.isOpen = false;
      });
    default:
      return state;
  }
};

export default reducer;
