import { SPINNER_ON, SPINNER_OFF } from './types';

const initialState = { open: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_ON:
      return { open: true };
    case SPINNER_OFF:
      return { open: false };
    default:
      return state;
  }
};

export default reducer;
