import _ from 'lodash';
import moment from 'moment';

import API_ROUTES from '../config/apiRoutes';
import { openModal } from '../redux/modal/actions';
import { offSpinner, onSpinner } from '../redux/spinner/actions';
import { getLoggedInUser } from '../redux/user/actions';
import { apiRequest } from '../utils/axios';

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.users.forgotPassword };
    config.data = { email };
    await apiRequest(config);
    return true;
  } catch (error) {
    if (error.status === 404) {
      dispatch(openModal({ type: 'error', title: '错误', message: '您的邮件未注册。' }));
    } else {
      dispatch(openModal({ type: 'error', title: '错误', message: '邮件发送失败，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const resetPassword = (userId, token, password, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.users.resetPassword };
    config.data = { userId, token, password };
    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '密码已重置，请前往登入。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    if (error.status === 404) {
      dispatch(
        openModal({
          type: 'error',
          title: '错误',
          message: '重置密码请求失败，请再重新申请一次。',
        }),
      );
    } else {
      dispatch(openModal({ type: 'error', title: '错误', message: '邮件发送失败，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const registerAccount = (registerInfo, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.users.register };
    // Change payload to required format
    const payload = _.omit(registerInfo, 'confirmPassword');
    payload.birthDate = moment(registerInfo.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    config.data = payload;

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '注册成功，请前往登入。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    // Error 409 indicates that email already been registered
    if (error.status === 409) {
      return 'conflict';
    } else {
      dispatch(openModal({ type: 'error', title: '错误', message: '注册失败，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const updateAccount = (userDetails, userId) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const config = { ...API_ROUTES.users.updateUser };
    // Change payload to required format
    const payload = { ...userDetails };
    payload.birthDate = moment(payload.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    config.data = payload;
    config.url = `${API_ROUTES.users.updateUser.url}/${userId}`;

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '更新成功。',
        primaryButtonCallback: () => dispatch(getLoggedInUser()),
      }),
    );
  } catch (error) {
    // Error 409 indicates that email already been registered
    if (error.status === 409) {
      return 'conflict';
    } else {
      dispatch(openModal({ type: 'error', title: '错误', message: '更新失败，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const updatePassword = (password, userId) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    // Get API route and put data in payload
    const { url, method } = { ...API_ROUTES.users.changePassword };
    const config = {
      url: url(userId),
      method,
    };
    config.data = {
      currentPassword: password.currentPassword,
      newPassword: password.newPassword,
    };

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '更新成功。',
      }),
    );
  } catch (error) {
    // Error 401 indicates the current password provided is invalid
    if (error.status === 401) {
      return 'unauthorized';
    } else {
      dispatch(openModal({ type: 'error', title: '错误', message: '更新失败，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};
