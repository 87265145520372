import { offSpinner, onSpinner } from '../spinner/actions';
import API_ROUTES from '../../config/apiRoutes';
import { apiRequest } from '../../utils/axios';
import { GET_LOGGED_IN_USER, REMOVE_LOGGED_IN_USER } from './types';
import * as AccessTokenController from '../../utils/accessToken';

export const getLoggedInUser = () => {
  return async (dispatch) => {
    try {
      dispatch(onSpinner());

      // Send API request to get logged in user info based on access token
      const config = { ...API_ROUTES.users.getLoggedIn };
      const response = await apiRequest(config);

      if (!response.details) {
        throw new Error('无法获取登入用户资料');
      }

      dispatch({ type: GET_LOGGED_IN_USER, payload: response.details });

      return response.details;
    } catch (error) {
      dispatch(logout(true));
    } finally {
      dispatch(offSpinner());
    }
  };
};

export const logout = (skipRevokeApi = false) => {
  return async (dispatch) => {
    try {
      dispatch(onSpinner());

      if (!skipRevokeApi) {
        // Send API request to revoke refresh token
        const config = { ...API_ROUTES.auth.logout };
        config.withCredentials = true;
        await apiRequest(config);
      }
    } catch (error) {
      return;
    } finally {
      dispatch({
        type: REMOVE_LOGGED_IN_USER,
      });
      AccessTokenController.setAccessToken('');
      dispatch(offSpinner());
    }
  };
};
