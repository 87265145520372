import moment from 'moment';
import { API_URL } from '../../../../config/env';
import { getBase64 } from '../../../../utils/convertBase64';

export const INITIAL_STATE = {
  name: '',
  activityDate: '',
};

export const INITIAL_DETAILS = {
  image: null,
  description: '',
  imageName: '',
  imageSrc: '',
};

export const validateData = (activity, details) => {
  const errorState = { ...INITIAL_STATE, imageName: '' };

  if (activity.name.length === 0) {
    errorState.name = '请把活动名称填上';
  }
  if (activity.activityDate.length === 0) {
    errorState.activityDate = '请把活动日期填上';
  } else if (moment(activity.activityDate).isBefore(new Date(), 'day')) {
    errorState.activityDate = '活动日期必须在今日以后';
  }
  if (details[0].imageName === '') {
    errorState.imageName = '请上传图片';
  }

  const hasError = Object.values(errorState).some((value) => value !== '');

  return { errorState, hasError };
};

export const getImageSrc = async (detail) => {
  if (detail.image) {
    return await getBase64(detail.image);
  } else if (detail.imageName) {
    return `${API_URL}/images/${detail.imageName}`;
  } else {
    return '';
  }
};
