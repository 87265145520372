import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F6F6',
  },
  editButton: {
    backgroundColor: 'rgb(207, 207, 207)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(207, 207, 207, 0.8)',
    },
  },
}));
