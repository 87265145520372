import API_ROUTES from '../config/apiRoutes';
import { openModal } from '../redux/modal/actions';
import { onSpinner, offSpinner } from '../redux/spinner/actions';
import { apiRequest } from '../utils/axios';

export const getAllRegistrations = (activityId) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.registrations.getAllRegistrations };
    // Only retrieve master class activities
    config.params = { activityId };

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const updateRegistrationStatus =
  (oldRegistrations, newRegistrations, activityName, activityDate, callback) =>
  async (dispatch) => {
    try {
      dispatch(onSpinner());
      const config = { ...API_ROUTES.registrations.bulkUpdateRegistrations };
      // Compare old registrations and new registrations, then create payload to update only registrations that has changes
      const payload = [];
      newRegistrations.forEach((registration, index) => {
        if (registration.status !== oldRegistrations[index]?.status) {
          payload.push({
            id: registration._id,
            status: registration.status,
            name: registration.user.name,
            email: registration.user.email,
            activityName,
            activityDate,
          });
        }
      });
      config.data = payload;

      await apiRequest(config);

      dispatch(
        openModal({
          type: 'success',
          title: '成功',
          message: '成功更新注册状态。',
          primaryButtonCallback: callback,
        }),
      );
    } catch (error) {
      dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
    } finally {
      dispatch(offSpinner());
    }
  };

export const getUserRegistrations = () => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.registrations.getUserRegistrations };
    // Send API request to get all user registered activities
    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const getRegistration = (registrationId) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.registrations.getRegistration };
    config.url = `${API_ROUTES.registrations.getRegistration.url}/${registrationId}`;
    // Send API request to get the specific registration
    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    if (error.status !== 401) {
      dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
    }
  } finally {
    dispatch(offSpinner());
  }
};

export const updateBankslip = (bankSlip, id, skipApi, callback) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    if (!skipApi) {
      const config = { ...API_ROUTES.registrations.updateRegistration };
      config.url = `${API_ROUTES.registrations.updateRegistration.url}/${id}`;

      config.data = {
        bankSlip,
      };

      await apiRequest(config);
    }

    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '更新成功。',
        primaryButtonCallback: callback,
      }),
    );
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};

export const createRegistration = (inputActivityId) => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.registrations.createRegistration };

    config.data = {
      activityId: inputActivityId,
    };

    await apiRequest(config);
    dispatch(
      openModal({
        type: 'success',
        title: '成功',
        message: '报名成功。',
        // primaryButtonCallback: 'callback',
      }),
    );
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '您已经报名参加该课程！' }));
  } finally {
    dispatch(offSpinner());
  }
};
