import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useStyles } from './styles';
import Button from '../../../shared/Button';
import TextField from '../../../shared/TextField';
import { INITIAL_STATE, INITIAL_DETAILS, validateData, getImageSrc } from './utils';
import {
  createActivity,
  getActivity,
  editActivity,
  deleteActivity,
} from '../../../../api/activity';
import { openModal } from '../../../../redux/modal/actions';

const AddActivity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const isMasterclass = location.pathname.includes('masterclass');

  const [activity, setActivity] = useState({ ...INITIAL_STATE });
  const [validationState, setValidationState] = useState({ ...INITIAL_STATE });
  const [details, setDetails] = useState([{ ...INITIAL_DETAILS }]);

  useEffect(() => {
    if (id) {
      dispatch(getActivity(id)).then(async (response) => {
        if (!response) {
          dispatch(
            openModal({
              type: 'error',
              title: '错误',
              message: `找不到该${isMasterclass ? '课程' : '活动'}。`,
              primaryButtonCallback: () =>
                history.replace(`/admin/${isMasterclass ? 'masterclass' : 'events'}/edit`),
            }),
          );
        } else {
          setActivity({
            name: response.name,
            activityDate: moment(response.activityDate).format('YYYY-MM-DD'),
          });
          const details = [];
          for (const detail of response.details) {
            details.push({
              image: null,
              description: detail.description,
              imageName: detail.image,
              imageSrc: await getImageSrc({ imageName: detail.image }),
            });
          }
          setDetails(details);
        }
      });
    } else {
      setActivity({ ...INITIAL_STATE });
      setValidationState({ ...INITIAL_STATE });
      setDetails([{ ...INITIAL_DETAILS }]);
    }
  }, [dispatch, id, history, isMasterclass]);

  const addDetails = () => {
    setDetails((prevState) => {
      const tempState = prevState.concat();
      tempState.push({ ...INITIAL_DETAILS });
      return tempState;
    });
  };

  const handleReUploadClick = (index) => {
    setDetails((prevState) => {
      const tempState = prevState.concat();
      tempState[index].image = null;
      tempState[index].imageName = '';
      tempState[index].imageSrc = '';
      return tempState;
    });
  };

  const uploadImage = (index) => async (image) => {
    if (image && image[0]) {
      const imageSrc = await getImageSrc({ image: image[0] });
      setDetails((prevState) => {
        const tempState = prevState.concat();
        tempState[index].image = image[0];
        tempState[index].imageName = image[0]?.name;
        tempState[index].imageSrc = imageSrc;
        return tempState;
      });
    } else {
      setDetails((prevState) => {
        const tempState = prevState.concat();
        tempState[index].image = null;
        tempState[index].imageName = '';
        tempState[index].imageSrc = '';
        return tempState;
      });
    }
  };

  const handleSubmit = () => {
    const { errorState, hasError } = validateData(activity, details);
    setValidationState(errorState);

    if (!hasError) {
      if (id) {
        dispatch(
          editActivity(id, activity, details, () => {
            history.push(`/admin/${isMasterclass ? 'masterclass' : 'events'}/edit`);
          }),
        );
      } else {
        dispatch(
          createActivity(activity, details, isMasterclass ? 'masterclass' : 'event', () => {
            history.push(`/admin/${isMasterclass ? 'masterclass' : 'events'}/edit`);
          }),
        );
      }
    }
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        type: 'warning',
        title: '注意',
        message: `确定要删除此${isMasterclass ? '课程' : '活动'}？`,
        primaryButtonCallback: () => {
          dispatch(
            deleteActivity(id, () => {
              history.push(`/admin/${isMasterclass ? 'masterclass' : 'events'}/edit`);
            }),
          );
        },
        primaryButtonText: '确定',
        secondaryButtonText: '取消',
      }),
    );
  };

  return (
    <Container className={classes.mainContainer} maxWidth="lg">
      <Typography variant="h4" className={classes.pageTitle}>
        {id ? `更改${isMasterclass ? '课程' : '活动'}` : `添加${isMasterclass ? '课程' : '活动'}`}
      </Typography>
      <Grid container className={classes.formContainer} spacing={2}>
        <Grid item container xs={12} md={6} alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              {isMasterclass ? '课程' : '活动'}名称：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <TextField
              label=""
              type="text"
              name="name"
              value={activity.name}
              onChange={(e) => setActivity((prevState) => ({ ...prevState, name: e.target.value }))}
              backgroundColor="white"
              fullWidth
              error={!!validationState.name}
              helperText={validationState.name}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} alignItems="center">
          <Grid item xs={5} md={3} lg={2}>
            <Typography variant="body1" component="span">
              {isMasterclass ? '课程' : '活动'}日期：
            </Typography>
          </Grid>
          <Grid item xs={7} md={9} lg={10}>
            <TextField
              label=""
              type="date"
              name="activityDate"
              value={activity.activityDate}
              onChange={(e) =>
                setActivity((prevState) => ({ ...prevState, activityDate: e.target.value }))
              }
              backgroundColor="white"
              fullWidth
              error={!!validationState.activityDate}
              helperText={validationState.activityDate}
              helperTextStyle={classes.errorHelperText}
            />
          </Grid>
        </Grid>
        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <Grid item container xs={12} md={6} alignItems="center">
              <Grid item xs={5} md={3} lg={2}>
                <Typography variant="body1" component="span">
                  配文：
                </Typography>
              </Grid>
              <Grid item xs={7} md={9} lg={10}>
                <TextField
                  label=""
                  type="text"
                  name="description"
                  value={detail.description}
                  onChange={(e) =>
                    setDetails((prevState) => {
                      const tempState = prevState.concat();
                      tempState[index].description = e.target.value;
                      return tempState;
                    })
                  }
                  rows={3}
                  backgroundColor="white"
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} alignItems="center">
              <Grid item xs={5} md={3} lg={2}>
                <Typography variant="body1" component="span">
                  图片：
                </Typography>
              </Grid>
              <Grid item xs={7} md={9} lg={10}>
                {!detail.imageName ? (
                  <>
                    <Dropzone
                      accept="image/*"
                      noClick
                      maxFiles={1}
                      maxSize={50000000}
                      multiple={false}
                      onDrop={uploadImage(index)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className={`${classes.imageContainer} ${
                            validationState.imageName && index === 0 && classes.errorBorder
                          }`}
                        >
                          <input {...getInputProps()} />
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CloudUploadIcon className={classes.uploadIcon} />
                            <Typography variant="body1" component="span" gutterBottom>
                              把图片拖放到此处
                            </Typography>
                            <button
                              onClick={() => {
                                getInputProps().ref?.current?.click();
                              }}
                            >
                              上传
                            </button>
                          </Grid>
                        </div>
                      )}
                    </Dropzone>
                    {validationState.imageName && index === 0 && (
                      <span className={classes.errorHelperText}>{validationState.imageName}</span>
                    )}
                  </>
                ) : (
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <img src={detail.imageSrc} className={classes.activityImage} />
                    <button
                      onClick={() => {
                        handleReUploadClick(index);
                      }}
                      style={{ marginTop: '8px' }}
                    >
                      重新上传
                    </button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item container xs={12} justifyContent="flex-start">
          <Grid>
            <Button
              variant="btn-add-details"
              sharedClasses="normal-text"
              label="添加图片与配文"
              onClick={addDetails}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={id ? 'space-between' : 'flex-end'}
        spacing={2}
        style={{ marginTop: '8px' }}
      >
        {id && (
          <Grid item>
            <Button
              variant="danger"
              sharedClasses="normal-text"
              label={isMasterclass ? '删除课程' : '删除活动'}
              onClick={handleDelete}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            variant="primary"
            sharedClasses="normal-text"
            label={id ? '保存' : '提交'}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddActivity;
