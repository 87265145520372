import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import Button from '../shared/Button';
import ServiceContent from '../ServiceContent';
import { serviceList } from './serviceList';
import styles from './styles.module.css';
import joinUsOri from '../../images/join-us.jpeg';
import homeSec from '../../images/home-second.png';
// import home1 from '../../images/home-1.webp';
// import home2 from '../../images/home-2.webp';
// import home3 from '../../images/home-3.webp';
// import home4 from '../../images/home-4.webp';
// import home5 from '../../images/home-6.png';
import value from '../../images/value.png';
import valueTitle from '../../images/value-title.png';
import value1 from '../../images/value1.png';
import value2 from '../../images/value2.png';
import value3 from '../../images/value3.png';
import value4 from '../../images/value4.png';
import culture from '../../images/culture.png';
import cultureMobile from '../../images/culture-mobile.png';
import joinUs from '../../images/joinUs.png';
import joinUsMobile from '../../images/joinUs-mobile.png';

const Home = () => {
  const [serviceRadio, setServiceRadio] = useState(1);
  const [slideNavigator, setSlideNavigator] = useState(1);
  const handleServiceRadioChange = (e) => {
    setServiceRadio(parseInt(e.target.value));
  };
  const handleNavigatorChange = (e) => {
    setSlideNavigator(parseInt(e.target.value));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (slideNavigator >= 5) {
        setSlideNavigator(1);
      } else {
        setSlideNavigator(slideNavigator + 1);
      }
    }, 7000);
    return () => clearInterval(interval);
  }, [slideNavigator]);

  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isShortDevice = useMediaQuery({ maxHeight: '450px' });
  const isSmallDevice = useMediaQuery({ maxWidth: '750px' });
  const isMobileScreen = useMediaQuery({ maxWidth: '1000px' });
  return (
    <>
      <div className={styles['home-container']}>
        {/* <div className={`${styles['slideshow-container']} ${isPortrait ? 'short' : ''}`}>
          <div
            className={`${styles['slideshow-image-container']} 
            ${
              slideNavigator == 1
                ? styles['ml-0']
                : slideNavigator == 2
                ? styles['ml-100']
                : slideNavigator == 3
                ? styles['ml-200']
                : slideNavigator == 4
                ? styles['ml-300']
                : slideNavigator == 5
                ? styles['ml-400']
                : ''
            }
                ${isPortrait ? 'short' : ''}`}
          >
            <img src={home1} />
            <img src={home2} />
            <img src={home3} />
            <img src={home4} />
            <img src={home5} />
            {/* <img src={test2} />
          </div>
          <div
            className={`${styles['slideshow-text-container']} ${isPortrait ? 'short' : ''} ${
              slideNavigator == 1
                ? styles['ml-0']
                : slideNavigator == 2
                ? styles['ml-100']
                : slideNavigator == 3
                ? styles['ml-200']
                : slideNavigator == 4
                ? styles['ml-300']
                : slideNavigator == 5
                ? styles['ml-400']
                : ''
            }`}
          >
            <div className={styles['flex-container']}>
              <div className={`${styles['slideshow-text']} ${isPortrait ? 'full' : ''}`}>
                <p className={`${styles['small-text']} ${isSmallDevice ? 'mobileSmallText' : ''}`}>
                  保障你的未来 理财 投资
                </p>
                <p
                  className={`${styles['slide-big-text']} ${isSmallDevice ? 'mobileBigText' : ''}`}
                >
                  财务规划
                </p>
                <p
                  className={`${styles['normal-text']} ${isSmallDevice ? 'mobileNormalText' : ''}`}
                >
                  协助您定制个人预算和储蓄目标，实施风险管理和税务规划策略
                  (次页仅为示范也，内容尚未定案，请决定内容)
                </p>
                <Button
                  label="详情"
                  onClick={() => (window.location.href = '/service')}
                  variant="btn-learn-more slideshow-margin"
                ></Button>
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={`${styles['slideshow-text']} ${isPortrait ? 'full' : ''}`}>
                <p className={`${styles['small-text']} ${isSmallDevice ? 'mobileSmallText' : ''}`}>
                  seo小标题, 请列出关键词
                </p>
                <p
                  className={`${styles['slide-big-text']} ${isSmallDevice ? 'mobileBigText' : ''}`}
                >
                  请选择内容
                </p>
                <p
                  className={`${styles['normal-text']} ${isSmallDevice ? 'mobileNormalText' : ''}`}
                >
                  请列出相关介绍，辅助内容 建议不超过60个中文字 <br /> **
                  <b>所有照片都无需授权即可使用于商业用途</b>**
                </p>
                <Button
                  label="详情"
                  onClick={() => (window.location.href = '/service')}
                  variant="btn-learn-more slideshow-margin"
                ></Button>
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={`${styles['slideshow-text']} ${isPortrait ? 'full' : ''}`}>
                <p className={`${styles['small-text']} ${isSmallDevice ? 'mobileSmallText' : ''}`}>
                  seo小标题, 请列出关键词
                </p>
                <p
                  className={`${styles['slide-big-text']} ${isSmallDevice ? 'mobileBigText' : ''}`}
                >
                  请选择内容
                </p>
                <p
                  className={`${styles['normal-text']} ${isSmallDevice ? 'mobileNormalText' : ''}`}
                >
                  请列出相关介绍，辅助内容 建议不超过60个中文字 <br /> **
                  <b>所有照片都无需授权即可使用于商业用途</b>**
                </p>
                <Button
                  label="详情"
                  onClick={() => (window.location.href = '/service')}
                  variant="btn-learn-more slideshow-margin"
                ></Button>
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={`${styles['slideshow-text']} ${isPortrait ? 'full' : ''}`}>
                <p className={`${styles['small-text']} ${isSmallDevice ? 'mobileSmallText' : ''}`}>
                  seo小标题, 请列出关键词, 请列出关键词
                </p>
                <p
                  className={`${styles['slide-big-text']} ${isSmallDevice ? 'mobileBigText' : ''}`}
                >
                  请选择内容
                </p>
                <p
                  className={`${styles['normal-text']} ${isSmallDevice ? 'mobileNormalText' : ''}`}
                >
                  请列出相关介绍，辅助内容 建议不超过60个中文字 <br /> **
                  <b>所有照片都无需授权即可使用于商业用途</b>**
                </p>
                <Button
                  label="详情"
                  onClick={() => (window.location.href = '/service')}
                  variant="btn-learn-more slideshow-margin"
                ></Button>
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={`${styles['slideshow-text']} ${isPortrait ? 'full' : ''}`}>
                <p className={`${styles['small-text']} ${isSmallDevice ? 'mobileSmallText' : ''}`}>
                  seo小标题, 请列出关键词
                </p>
                <p
                  className={`${styles['slide-big-text']} ${isSmallDevice ? 'mobileBigText' : ''}`}
                >
                  请选择内容
                </p>
                <p
                  className={`${styles['normal-text']} ${isSmallDevice ? 'mobileNormalText' : ''}`}
                >
                  请列出相关介绍，辅助内容 建议不超过60个中文字 <br /> **
                  <b>所有照片都无需授权即可使用于商业用途</b>**
                </p>
                <Button
                  label="详情"
                  onClick={() => (window.location.href = '/service')}
                  variant="btn-learn-more slideshow-margin"
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['overlay-container']} ${isPortrait ? 'short' : ''}`}>
          <div className={styles['gradient-overlay']}></div>
        </div>
        <div onChange={handleNavigatorChange} className="none">
          <input type="radio" name="radio-btn" id="radio1" value={1} defaultChecked />
          <input type="radio" name="radio-btn" id="radio2" value={2} />
          <input type="radio" name="radio-btn" id="radio3" value={3} />
          <input type="radio" name="radio-btn" id="radio4" value={4} />
          <input type="radio" name="radio-btn" id="radio5" value={5} />
        </div>
        <div className={`${styles['slideshow-navigator-container']} ${isPortrait ? 'short' : ''}`}>
          <div className={styles['navigator-container']}>
            <div className={styles['navigation-filler']}>
              <div className={slideNavigator == 1 ? 'white' : 'transparent'}></div>
              <div className={slideNavigator == 2 ? 'white' : 'transparent'}></div>
              <div className={slideNavigator == 3 ? 'white' : 'transparent'}></div>
              <div className={slideNavigator == 4 ? 'white' : 'transparent'}></div>
              <div className={slideNavigator == 5 ? 'white' : 'transparent'}></div>
            </div>
            <div className={styles['navigation-manual']}>
              <label htmlFor="radio1" className={slideNavigator == 1 ? 'block' : 'none'}></label>
              <div>
                <label htmlFor="radio1">.</label>
              </div>
              <div>
                <label htmlFor="radio2">.</label>
              </div>
              <div>
                <label htmlFor="radio3">.</label>
              </div>
              <div>
                <label htmlFor="radio4">.</label>
              </div>
              <div>
                <label htmlFor="radio5">.</label>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className={`${styles['catchphrase-container']} ${
            isPortrait ? `${styles['catchphraseMinHeight']}` : ''
          }`}
        >
          <div
            className={`${styles['image-container']} ${
              isPortrait ? 'auto-vh' : styles['width-50vw']
            }`}
          >
            <img src={homeSec} className={isPortrait ? 'fullWidthImg' : ''} />
          </div>
          <div
            className={`${styles['catchphrase-text-container']} 
            ${isSmallDevice && !isPortrait ? 'mobileTextDiv' : ''}
            ${isSmallDevice && isPortrait ? 'reset-margin-transform full catchphraseMobile' : ''}
            `}
          >
            <div className={`${styles['small-text']} ${isSmallDevice ? 'mobileSmallText' : ''}`}>
              在SMARTg
            </div>
            <div className={`${styles['big-text']} ${isSmallDevice ? 'mobileBigText' : ''}`}>
              我们为你
              <br />
              量身定制人生规划
            </div>
            <div className={`${styles['normal-text']} ${isSmallDevice ? 'mobileNormalText' : ''}`}>
              {/* 若有任何想传达的信息，故事，内容，可以在这里展示。 */}
            </div>
          </div>
        </div>
        {/* <div className={styles['service-container']}>
          <div className={styles['service-navigator']}>
            <div onChange={handleServiceRadioChange}>
              <input
                type="radio"
                name="service-radio"
                id="service-radio-1"
                value={1}
                defaultChecked
              />
              <input type="radio" name="service-radio" id="service-radio-2" value={2} />
              <input type="radio" name="service-radio" id="service-radio-3" value={3} />
              <input type="radio" name="service-radio" id="service-radio-4" value={4} />
            </div>
            <div>
              <label htmlFor="service-radio-1" className={serviceRadio == 1 ? 'selected' : ''}>
                财务规划
              </label>
            </div>
            <div>
              <label htmlFor="service-radio-2" className={serviceRadio == 2 ? 'selected' : ''}>
                财富积累
              </label>
            </div>
            <div>
              <label htmlFor="service-radio-3" className={serviceRadio == 3 ? 'selected' : ''}>
                遗产规划
              </label>
            </div>
            <div>
              <label htmlFor="service-radio-4" className={serviceRadio == 4 ? 'selected' : ''}>
                团体保险
              </label>
            </div>
          </div>
          {serviceList.map((service, index) => (
            <ServiceContent
              smallText={service.smallText}
              bigText={service.bigText}
              normalText={service.normalText}
              link={service.link}
              image={service.image}
              key={index}
              display={serviceRadio == index + 1 ? 'block' : 'none'}
            ></ServiceContent>
          ))}
        </div> */}

        {/* <div className={`${styles['value-div']} ${isMobileScreen ? 'block' : ''}`}>
          <div className={`${styles['left-title-div']} ${isMobileScreen ? styles['pb-40'] : ''}`}>
            <span className={styles['ch-title']}>| 理念</span>
          </div>
          <div className={`${styles['right-content-div']}`}>
            <div className={styles['value-component']}>
              <p>01</p>
              <p></p>
              <p>成为大马顶尖 20%，并且提供卓越数码金融服务</p>
            </div>
            <div className={styles['value-component']}>
              <p>02</p>
              <p></p>
              <p>传递理财规划的重要性，帮组客户寻找解决方案让生活更精彩</p>
            </div>
            <div className={styles['value-component']}>
              <p>03</p>
              <p></p>
              <p>提供专业理财解决方案，拿走担忧以及完成梦想，持续为客户创造最大价值</p>
            </div>
            <div className={styles['value-component']}>
              <p>04</p>
              <p></p>
              <p>激发与培养高素质的年轻人，让大众转移财务风险与建立财富安全网</p>
            </div>
          </div>
        </div> */}

        {isPortrait ? (
          <div className={styles['mobile-value-image']}>
            <div className={styles['mobile-value-title']}>
              <img src={valueTitle}></img>
            </div>
            <div className={styles['mobile-values']}>
              <div className={styles['row']}>
                <img src={value1}></img>
                <img src={value2}></img>
              </div>
              <div className={styles['row']}>
                <img src={value3}></img>
                <img src={value4}></img>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles['value-image']}>
            <img src={value}></img>
          </div>
        )}
        {/* <p className={styles['side-info-title']}>章节标题/识内容决定</p>
        <div
          className={`${styles['side-info-container']} ${
            isMobileScreen ? 'block ph-5percent' : ''
          }`}
        >
          <div className={styles['side-info']}>
            <p>其他资讯 标题1</p>
            <hr />
            <p>如果有其他资讯非产品/服务相关资讯想分享，可以放在这里</p>
            <Button
              label="详情"
              onClick={() => (window.location.href = '/')}
              variant="btn-learn-more side-info-margin"
            ></Button>
          </div>
          <div className={styles['side-info']}>
            <p>其他资讯 标题2</p>
            <hr />
            <p>如果有其他资讯非产品/服务相关资讯想分享，可以放在这里</p>
            <Button
              label="详情"
              onClick={() => (window.location.href = '/')}
              variant="btn-learn-more side-info-margin"
            ></Button>
          </div>
        </div> */}

        <div className={styles['']}></div>
        <div className={styles['culture-image']}>
          {isPortrait ? <img src={cultureMobile}></img> : <img src={culture}></img>}
        </div>

        {/* <div className={styles['join-us']}>
          <div className={styles['join-us-text']}>
            <p>成为 SMARTg 的一员!</p>
            <p>
              90%合作伙伴都是学士毕业
              <br />
              系统化业务培训1 对 1培训
            </p>
            <Link to="/career">
              <Button
                label="加入我们"
                variant={`btn-border ${isShortDevice ? 'btn-border-sm' : ''} 
              ${isPortrait ? 'btn-border-sm' : ''}`}
              ></Button>
            </Link>
          </div>
          <div className={styles['overlay']}></div>
          <div
            className={`${styles['join-us-img']} ${
              isPortrait ? 'height-80-container' : 'width-100-container'
            }`}
          >
            <img src={joinUsOri} />
          </div>
        </div> */}
        <div className={styles['join-us-image']}>
          {isPortrait ? <img src={joinUsMobile}></img> : <img src={joinUs}></img>}
        </div>
      </div>
    </>
  );
};

export default Home;
