import moment from 'moment';

import API_ROUTES from '../config/apiRoutes';
import { openModal } from '../redux/modal/actions';
import { onSpinner, offSpinner } from '../redux/spinner/actions';
import { apiRequest } from '../utils/axios';

export const createDISCResult = (userInfo, answers) => {
  return async (dispatch) => {
    try {
      dispatch(onSpinner());
      // Get API route and put data in payload
      const config = { ...API_ROUTES.disc.create };
      // Convert answers to API required payload format
      const result = { D: 0, I: 0, S: 0, C: 0 };
      for (const type of answers) {
        if (type) {
          result[type] = ++result[type];
        }
      }
      userInfo.birthDate = moment(userInfo.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
      config.data = { ...userInfo, result };

      await apiRequest(config);
    } catch (error) {
      dispatch(openModal({ type: 'error', title: '错误', message: '无法提交，请稍后再试。' }));
    } finally {
      dispatch(offSpinner());
    }
  };
};

export const getAllDiscResults = () => async (dispatch) => {
  try {
    dispatch(onSpinner());
    const config = { ...API_ROUTES.disc.getResult };

    const response = await apiRequest(config);
    return response.details;
  } catch (error) {
    dispatch(openModal({ type: 'error', title: '错误', message: '系统错误，请稍后再试。' }));
  } finally {
    dispatch(offSpinner());
  }
};
