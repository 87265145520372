import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import MetaTags from 'react-meta-tags';

import { getLoggedInUser } from './redux/user/actions';
import Home from './components/Home';
import Service from './components/Service';
import Masterclass from './components/Masterclass';
// import Event from './components/Event';
import DISC from './components/DISC';
// import DISC from './components/DiscResult';
import About from './components/About';
import Navbar from './components/shared/Navbar';
import Footer from './components/shared/Footer';
import Spinner from './components/shared/Spinner';
import Modal from './components/shared/Modal';
import Login from './components/Auth/Login';
import NotFound from './components/NotFound';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import Register from './components/user/Register';
import ContactUs from './components/ContactUs/ContactUs';
import Career from './components/Career/Career';
import AddActivity from './components/admin/activity/AddActivity';
import Sidebar from './components/shared/Sidebar';
import ActivityTable from './components/admin/activity/ActivityTable';
import AddCareer from './components/admin/career/AddCareer';
import CareerTable from './components/admin/career/CareerTable';
import RegistrationTable from './components/admin/registration/RegistrationTable';
import RegistrationList from './components/admin/registration/RegistrationList';
import DiscTable from './components/admin/disc/DiscTable';
import UserProfile from './components/user/UserProfile';
import ChangePassword from './components/user/ChangePassword';
import MasterclassRegistration from './components/user/MasterclassRegistration';
import MasterclassRegistrationDetails from './components/user/MasterclassRegistrationDetails';
import AddPersonInCharge from './components/admin/personInCharge/AddPersonInCharge';
import PersonInChargeLibrary from './components/admin/personInCharge/PersonInChargeLibrary';

const RootRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSubDomain = window.location.hostname.includes('disc.smartg.my');

  const user = useSelector((state) => state.user);
  const isAdmin = user?.loggedIn && user?.details?.role === 'admin';
  const isMobileScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  // const showHeader = !['/disc', '/masterclass'].some((route) => location.pathname === route);
  const showHeader = !['/disc'].some((route) => location.pathname === route);
  const showFooter = !['/disc', '/masterclass', '/event'].some(
    (route) => location.pathname === route,
  );
  const isTransparent = ['/'].some((route) => location.pathname === route);
  const showAdminSidebar = location.pathname.includes('admin') && isAdmin;
  const showUserSidebar = location.pathname.includes('user');

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, [dispatch]);

  return (
    <>
      <CssBaseline />
      {isSubDomain ? (
        <SubDomainRoutes />
      ) : (
        <>
          {showHeader && (
            <header>
              <Navbar isSolid={!isTransparent} isWhite={!isTransparent} />
              <MetaTags>
                <title>SMARTg 精明理财</title>
                <meta
                  name="description"
                  content="SMARTg was established by Ms Goh Ee Lin, excelled in providing excellent financial services"
                />
                <meta property="og:title" content="SMARTg 精明理财" />
                {/* <meta property="og:type" content="article" /> */}
                {/* <meta property="og:image" content="path/to/image.jpg" /> */}
              </MetaTags>
            </header>
          )}
          {(showAdminSidebar || showUserSidebar) && (
            <aside>
              <Sidebar type={showAdminSidebar ? 'admin' : 'user'} />
            </aside>
          )}
          <main>
            <div
              // SET THE WHITE MARGIN UNDER NAVBAR
              style={{
                marginTop: isTransparent ? '0' : '75px',
                marginLeft:
                  (showAdminSidebar || showUserSidebar) && !isMobileScreen ? '200px' : '0',
              }}
            >
              <MainRoutes />
            </div>
          </main>
          {showFooter && !showAdminSidebar && !showUserSidebar && (
            <footer>
              <Footer />
            </footer>
          )}
        </>
      )}
      <Modal />
      <Spinner />
    </>
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user);
  const isAdmin = user?.loggedIn && user?.details?.role === 'admin';

  return (
    <Route {...rest} render={(props) => (isAdmin ? <Component {...props} /> : <NotFound />)} />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

const MainRoutes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/service" component={Service} />
    <Route exact path="/masterclass" component={Masterclass} />
    {/* <Route exact path="/event" component={Event} /> */}
    <Route exact path="/auth" component={Login} />
    <Route exact path="/auth/forgot-password" component={ForgotPassword} />
    <Route exact path="/auth/reset-password/:userId/:token" component={ResetPassword} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/contact-us" component={ContactUs} />
    <Route exact path="/about" component={About} />
    <Route exact path="/career" component={Career} />
    <Route exact path="/disc" component={DISC} />
    {/* User Routes */}
    <Route exact path="/user/profile" component={UserProfile} />
    <Route exact path="/user/password" component={ChangePassword} />
    <Route exact path="/user/registrations" component={MasterclassRegistration} />
    <Route exact path="/user/registrations/:id" component={MasterclassRegistrationDetails} />

    {/* Below are custom admin routes, logged in user must have admin role */}
    <AdminRoute exact path="/admin/masterclass/create" component={AddActivity} />
    <AdminRoute exact path="/admin/masterclass/edit" component={ActivityTable} />
    <AdminRoute exact path="/admin/masterclass/edit/:id" component={AddActivity} />
    <AdminRoute exact path="/admin/careers/create" component={AddCareer} />
    <AdminRoute exact path="/admin/careers/edit" component={CareerTable} />
    <AdminRoute exact path="/admin/careers/edit/:id" component={AddCareer} />
    <AdminRoute exact path="/admin/registrations" component={RegistrationTable} />
    <AdminRoute exact path="/admin/registrations/:id" component={RegistrationList} />
    <AdminRoute exact path="/admin/disc" component={DiscTable} />
    <AdminRoute exact path="/admin/person-in-charge/create" component={AddPersonInCharge} />
    <AdminRoute exact path="/admin/person-in-charge/edit/:id" component={AddPersonInCharge} />
    <AdminRoute exact path="/admin/person-in-charge/edit" component={PersonInChargeLibrary} />
    {/* Event is temporarily removed based on latest requirement as of 24/12/2021 */}
    {/* <AdminRoute exact path="/admin/events/create" component={AddActivity} /> */}
    {/* <AdminRoute exact path="/admin/events/edit" component={ActivityTable} /> */}
    {/* <AdminRoute exact path="/admin/events/edit/:id" component={AddActivity} /> */}

    {/* Not Found */}
    <Route component={NotFound} />
  </Switch>
);

const SubDomainRoutes = () => (
  <Switch>
    <Route exact path="/" component={DISC} />
    {/* Not Found */}
    <Route component={NotFound} />
  </Switch>
);

export default RootRoute;
