import React, { useEffect, useState } from 'react';
import { Container, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { Prompt, useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { useStyles } from './styles';
import { getActivity } from '../../../../api/activity';
import { openModal } from '../../../../redux/modal/actions';
import { getAllRegistrations, updateRegistrationStatus } from '../../../../api/registration';
import CustomTable from '../../../shared/Table';
import BankSlipPreview from './BankSlipPreview';
import Button from '../../../shared/Button';

const RegistrationList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  const [masterclass, setMasterclass] = useState();
  const [registrations, setRegistrations] = useState();
  const [oldRegistrations, setOldRegistrations] = useState();
  const [openBankSlip, setOpenBankSlip] = useState(false);
  const [bankSlip, setBankSlip] = useState();

  useEffect(() => {
    dispatch(getActivity(id)).then((response) => {
      if (!response) {
        dispatch(
          openModal({
            type: 'error',
            title: '错误',
            message: '找不到该课程。',
            primaryButtonCallback: () => history.replace(`/admin/registrations`),
          }),
        );
      } else {
        setMasterclass(response);
        dispatch(getAllRegistrations(id)).then((registrations) => {
          if (registrations) {
            setRegistrations(registrations);
            setOldRegistrations(registrations);
          }
        });
      }
    });
  }, [dispatch, id, history]);

  useEffect(() => {
    const changesMadeHandler = (e) => {
      if (!_.isEqual(registrations, oldRegistrations)) {
        e.preventDefault();
        return (e.returnValue = 'Changes you made may not be saved.');
      }
    };
    window.addEventListener('beforeunload', changesMadeHandler);
    return () => {
      window.removeEventListener('beforeunload', changesMadeHandler);
    };
  }, [registrations, oldRegistrations]);

  const handleBankSlipPreview = (bankSlip) => {
    setOpenBankSlip(true);
    setBankSlip(bankSlip);
  };

  const handleSubmit = () => {
    dispatch(
      updateRegistrationStatus(
        oldRegistrations,
        registrations,
        masterclass.name,
        masterclass.activityDate,
        () => {
          history.push(`/admin/registrations`);
        },
      ),
    );
  };

  const tableHeaders = ['姓名', '电子邮件', '付款收据', '状态'];
  const tableData = registrations?.map((registration, index) => [
    registration.user.name,
    registration.user.email,
    registration.bankSlip ? (
      <span
        onClick={() => handleBankSlipPreview(registration.bankSlip)}
        className={classes.previewText}
      >
        检查
      </span>
    ) : (
      '-'
    ),
    <FormControl variant="outlined" fullWidth key={registration._id}>
      <Select
        value={registration.status}
        onChange={(e) => {
          setRegistrations((prevState) => {
            const tempState = _.cloneDeep(prevState);
            tempState[index].status = e.target.value;
            return tempState;
          });
        }}
        name="status"
        className={classes[registration.status]}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
        }}
      >
        <MenuItem value="pending" className={classes.pending}>
          审核中
        </MenuItem>
        <MenuItem value="approved" className={classes.approved}>
          待付款
        </MenuItem>
        <MenuItem value="paid" className={classes.paid}>
          已付款
        </MenuItem>
        <MenuItem value="registered" className={classes.registered}>
          已报名
        </MenuItem>
        <MenuItem value="cancelled" className={classes.cancelled}>
          已取消
        </MenuItem>
      </Select>
    </FormControl>,
  ]);

  return (
    <Container className={classes.mainContainer} maxWidth="lg">
      <Prompt
        when={!_.isEqual(registrations, oldRegistrations)}
        message="Changes you made may not be saved."
      />
      <Typography variant="h4" className={classes.pageTitle}>
        课程：{masterclass?.name}
      </Typography>
      <Grid container className={classes.tableContainer}>
        <CustomTable tableHeaders={tableHeaders} tableData={tableData} />
      </Grid>
      <Grid container justifyContent="flex-end" style={{ marginTop: '16px' }}>
        <Grid item>
          <Button
            variant="primary"
            sharedClasses="normal-text"
            label="更新"
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
      {openBankSlip && <BankSlipPreview setOpenBankSlip={setOpenBankSlip} bankSlip={bankSlip} />}
    </Container>
  );
};

export default RegistrationList;
