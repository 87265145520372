import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.primary.main,
    minHeight: 'calc(100vh - 90px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  formContainer: {
    backgroundColor: 'white',
    borderRadius: '15px',
    padding: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
  hyperlink: {
    textDecoration: 'none',
    fontWeight: 700,
    '&:visited': {
      color: 'blue',
    },
  },
}));
